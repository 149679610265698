<div class="header-nav">
  <nav class="header-drop-nav">
    <ul>
      <li (clickOutside)="closeMenu()">
        <div (click)="openMenu()">
          <img src="./assets/images/menu_new.svg" alt="drop menu">
        </div>
        <ul class="drop-menu" *ngIf="showMenu">
          <li class="close-menu">
            <a (click)="openMenu()">
              <img src="./assets/images/icon-menu-close.svg" alt="close menu">
              <p>{{ 'menu.menu' | translate }}</p>
            </a>
          </li>
          <li class="drop-menu-list">
            <ul>
              <li *ngFor="let item of menuItems" class="{{ item.class ? item.class : '' }}">
                <a class="cursor-pointer"
                  [class.active-link]="curentUrl && curentUrl.includes(item.url)"
                  (click)="item.url === '#logout' ? logout($event) : handleCustomMenuClick($event, item.url)">
                  {{ item.title }}
                </a>
              </li>
              <app-login-logout
                class="hide-desktop"
                (closeMenu)="closeMenu()"
                [isMenu]="true"
                [curentUrl]="curentUrl">
              </app-login-logout>
              <app-switch-languages
                [noInit]="true"
                [fxFlex]="'100%'"
                [styles]="'margin: 20px 0 0;'">
              </app-switch-languages>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </nav>

  <nav class="header-main-nav">
    <ul>
      <li *ngFor="let item of additionalMenuItems">
        <a class="cursor-pointer" (click)="handleCustomMenuClick($event, item.url)">{{ item.title }}</a>
      </li>
    </ul>
  </nav>
</div>
