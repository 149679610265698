import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PolicyAssignService {
  constructor(private httpClient: HttpClient) {}

  policyAssignGetBody(form, userId) {
    if (form.get('number') && form.get('number').value && form.get('number').valid &&
      form.get('email') && form.get('email').value && form.get('email').valid) {
      return {
        user_id: userId,
        email: form.get('email').value,
        policy_number: form.get('number').value
      };
    }
    return null;
  }

  assignCheck(body) {
    return this.httpClient.post(`/policies/belongs`, body);
  }
}
