
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription } from 'rxjs';
import { combineLatest, take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services';
import { BrandingService } from 'src/app/core/services/branding.service';


@Component({
  selector: 'app-login-logout',
  templateUrl: './login-logout.component.html',
  styleUrls: ['./login-logout.component.scss']
})
export class HeaderLoginLogoutComponent implements OnInit, OnDestroy {

  @Input() isMenu = false;
  @Input() set curentUrl(url) {
    this.activeUrl = url;
  };
  @Output() closeMenu = new EventEmitter();
  activeUrl = null;
  links = [];
  private wasAuth = null;
  private linksSub: Subscription;

  constructor(
    private authService: AuthService,
    private brandingService: BrandingService,
    private translateService: TranslateService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.translateService.get('menu.login').pipe(take(1)).subscribe(() => {
      this.setLinks();
    })
  }

  ngOnDestroy(): void {
    this.linksSub.unsubscribe()
  }

  logout(event) {
    event.preventDefault();
    this.authService.signOut().subscribe();
    if (this.isMenu) {
      this.closeMenu.emit();
    }
  }

  handleClick(event, url): void {
    event.preventDefault();
    this.router.navigateByUrl(url);
    if (this.isMenu) {
      this.closeMenu.emit();
    }
  }

  private setLinks() {
    this.linksSub = of([]).pipe(
      combineLatest(
        this.brandingService.isLoginDisabled$,
        this.brandingService.isRegisterDisabled$,
        this.authService.isAuthenticated$
      )
    ).subscribe(([arr, loginDisabled, registerDisabled, isAuth]) => {
      if (this.wasAuth !== isAuth) {
        if (isAuth) {
          this.links = [
            { url: '/user/profile', title: this.translateService.instant('menu.my_profile') },
            { url: '#logout', title: this.translateService.instant('menu.logout') }
          ]
        } else {
          const links = [];
          if (!loginDisabled) {
            links.push({ url: '/auth/login', title: this.translateService.instant('menu.log_in') });
          }
          if (!registerDisabled) {
            links.push({ url: '/auth/register', title: this.translateService.instant('menu.register') });
          }
          this.links = links;
        }
        this.wasAuth = isAuth;
      }
    })
  }
}
