import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GetOrCreateResponse, ResidentialService } from '../../../core/services/residential.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { SmartyService } from '../smarty.service';
import { Subscription } from 'rxjs';
import { Insurable } from '../../../core/models/insurable.model';

@Component({
  selector: 'ui-address-smarty',
  templateUrl: './ui-address-smarty.component.html',
  styleUrls: ['./ui-address-smarty.component.scss']
})
export class UiAddressSmartyComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Output() setBuildingsFromAddress = new EventEmitter();
  @Output() setUnitsFromAddress = new EventEmitter();
  @Output() setAccountId = new EventEmitter();
  @Output() setCommunity = new EventEmitter();

  addresses = [];
  subscription: Subscription = new Subscription();
  loadingAddress = false;
  showAddressHint = false;

  constructor(private residentialService: ResidentialService,
              private smartyService: SmartyService) { }

  ngOnInit() {
    this.onAddressChanges();
  }

  onAddressChanges() {
    this.addresses = [];
    this.subscription.add(this.form.get('address').valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(800),
    ).subscribe(search => {
      if (search && typeof search === 'string' && search.length > 0) {
        this.loadingAddress = true;
        this.smartyService.searchBy(search).pipe(
          finalize(() => {
            this.showAddressHint = true;
            this.loadingAddress = false;
          })
        ).subscribe((res: any) => {
          const addresses = res && res.length > 0 ? res : [];
          if (addresses) {
            addresses.forEach(address => { address.full = this.buildAddress(address) })
          }
          this.addresses = addresses;
        });
      }
    }));
  }

  buildAddress(suggestion) {
    return suggestion.street_line + " " + suggestion.city + ", " + suggestion.state + " " + suggestion.zipcode;
  }

  selectAddress(address) {
    this.form.get('address').setValue(address.full, { emitEvent: false })
    this.getOrCreate();
  }

  handleAddressChange(googleAddress: Address) {
    const formattedAddress = this.residentialService.formatAddress(this.form.get('address').value, googleAddress);
    this.form.get('address').setValue(formattedAddress);
    const county = this.getLongName(this.setAddressPart(googleAddress, 'administrative_area_level_2'));
    const neighborhood = this.getLongName(this.setAddressPart(googleAddress, 'neighborhood'));
    this.form.get('county').setValue(county);
    this.form.get('neighborhood').setValue(neighborhood);
    this.getOrCreate();
  }

  getOrCreate(): void {
    this.form.get('insurable_id').setValue(null);
    this.form.get('address').setErrors(null);
    const form = this.form.value;

    this.residentialService.getOrCreateInsurable(form)
      .subscribe((result: GetOrCreateResponse) =>  {
        const community = result && result.results && result.results[0] ? result.results[0] : null;
        if (community) {
          this.validateStates(community)
          this.setCommunity.emit(community);
        }
        if (community && community.id) {
          this.form.get('insurable_id').setValue(community.id);
        }
        const accountId = community && community.account_id ? community.account_id : null;
        if (accountId) {
          this.setAccountId.emit(accountId);
        }
        this.setBuildings(community);
        this.setUnits(community);
      });
  }

  validateStates(community) {
    const primaryAddress = community && community.primary_address ? community.primary_address : null
    const state = primaryAddress && primaryAddress.state ? primaryAddress.state : null;
    if (state === 'AK' || state === 'HI') {
      this.form.get('address').setErrors({ invalidState: true });
    }
  }

  setAddressPart(googleAddress, part) {
    return googleAddress.address_components.find(ac => (ac.types || []).includes(part));
  }

  getLongName(address) {
    return address && address.long_name ? address.long_name : '';
  }

  setBuildings(community) {
    const buildings = community && community.buildings ? community.buildings : [];
    this.setBuildingsFromAddress.emit(buildings);
  }

  setUnits(community) {
    const units = community && community.units ? community.units : [];
    this.setUnitsFromAddress.emit(units);
  }

  displayFnInsurable(address) {
    return address && address.full ? address.full:
           address ? address : '';
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
