<mat-form-field fxFlex="100%" appearance="outline">
  <mat-label>Carrier</mat-label>
  <input [formControl]="carrierControl" [matAutocomplete]="autoCarrier" required matInput>
  <mat-autocomplete autoActiveFirstOption #autoCarrier="matAutocomplete" [displayWith]="displayFnCarrier">
    <mat-option *ngFor="let carrier of synonyms" [value]="carrier">
      <span class="caption">{{ carrier.title }}</span>
    </mat-option>
  </mat-autocomplete>
  <button *ngIf="carrierControl.value && carrierControl.enabled" mat-icon-button matSuffix (click)="reset()" type="button">
    <mat-icon>close</mat-icon>
  </button>
  <button *ngIf="!carrierControl.value && carrierControl.enabled" class="text-secondary" mat-icon-button matSuffix type="button">
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>
  <mat-error *ngIf="carrierControl.invalid">{{ 'tenant_boarding.select_carrier' | translate }}</mat-error>
  <mat-hint>{{ 'tenant_boarding.select_carrier' | translate }}</mat-hint>
</mat-form-field>
