import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import { ResidentialService } from '../../core/services/residential.service';
import { ToastrService } from 'ngx-toastr';
import { GuaranteeFormUtils } from '../../core/utils/guarantee.utils';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PaymentMsiService } from './payment-msi.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const DomUtils = {
  getEl: function getEl(selector) {
    return window.document.querySelector(selector);
  },
  hasClass: function hasClass(el, cssClass) {
    if (el.classList) {
      return el.classList.contains(cssClass);
    }

    return !!el.className.match(new RegExp('(\\s|^)'.concat(cssClass, '(\\s|$)')));
  },
  removeClass: function removeClass(el, cssClass) {
    if (el.classList) {
      el.classList.remove(cssClass);
    } else if (DomUtils.hasClass(el, cssClass)) {
      const reg = new RegExp('(\\s|^)'.concat(cssClass, '(\\s|$)'));
      el.className = el.className.replace(reg, ' ');
    }
  }
};
@UntilDestroy()
@Component({
  selector: 'app-payment-new-carrier',
  templateUrl: './payment-msi.component.html',
  styleUrls: ['./payment-msi.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentMsiComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() data;
  @Input() address: any;
  @Input() loading;
  @Input() hasBackButton;
  @Input() agreeWithDisclosureParent;
  @Input() subjectFormSubmit;
  @Output() cardStart = new EventEmitter();
  @Output() cardSuccess = new EventEmitter();
  @Output() cardError = new EventEmitter();
  @Output() declinePayment = new EventEmitter();
  @Output() openDisclosure =  new EventEmitter();
  agreeWithDisclosure = false;
  paymentForm;
  state;
  address1;
  address2;
  city;
  zip;
  stateList = GuaranteeFormUtils.STATE_LIST;
  errorMessages = {};
  formSent = false;
  mailingAddress = true;
  constructor(private resService: ResidentialService,
              private toastrService: ToastrService,
              private translate: TranslateService,
              private paymentMsiService: PaymentMsiService) {
  }
  hooks = {
    preFlowHook: (callback) => {
        if (!this.mailingAddress) {
            if (!this.address1 || !this.state || !this.city || !this.zip) {
                this.toastrService.error(this.translate.instant('form.form_validation_error'));
                this.cardError.emit();
            } else {
                this.resService.getExternalPaymentAuth(this.data.quote.id).subscribe((response) => {
                    callback(response);
                });
            }
            // if (!this.state) {
            //     this.toastrService.error(this.translate.instant('form.state_required'));
            //     this.cardError.emit();
            // } else if (!this.zip) {
            //     this.toastrService.error(this.translate.instant('form.postal_code_required'));
            //     this.cardError.emit();
            // } else {
            //     this.resService.getExternalPaymentAuth(this.data.quote.id).subscribe((response) => {
            //         callback(response);
            //     });
            // }
        } else {
             this.resService.getExternalPaymentAuth(this.data.quote.id).subscribe((response) => {
                 callback(response);
             });
        }
    },
    submitFormHook: (callbackFn) => {
        let body;

        if (this.mailingAddress) {
            body = {
                address1: this.address.street_name,
                region: this.address.state,
                postalCode: this.address.zip_code,
                city: this.address.city
            };

            if (this.address.street_two) {
                body.address2 = this.address.address_two;
            }
        } else {
            body = {
                address1: this.address1,
                region: this.state,
                postalCode: this.zip,
                city: this.city
            };

            if (this.address2) {
                body.address2 = this.address2;
            }
        }

        callbackFn(body);
    },
  };
  config = this.paymentMsiService.getConfig();

  onCreate = (paymentForm) => {
    this.paymentForm  = paymentForm;
    const onSuccess = (clientToken) => {
      this.resService.getPayeezyToken(clientToken).subscribe(
        (value) => {
            console.log('success');
          this.cardSuccess.emit(value);
        }, () => {
            console.log('error');
          this.cardError.emit();
        });
    };

    const onError = (error) => {
      const fields = Object.values<any>(this.paymentForm.state.fields);
      const errorFields = fields.filter(f => f && !f.validity.valid) ;
      console.log(errorFields);
      this.errorMessages = errorFields.reduce((acc, val) => {
        const errorMessage = val.validity.empty ? `${this.translate.instant('msi_error.the')} ${val.field} ${this.translate.instant('msi_error.field_is_required')}.` :
          `${this.translate.instant('msi_error.the')} ${val.field} ${this.translate.instant('msi_error.field_must_be_valid')}`;
        return {...acc, [val.field]: errorMessage};
      }, {});
      this.toastrService.error(this.translate.instant('form.form_validation_error'));
      this.cardError.emit();
    };
    const form = document.getElementById('firstdataForm');
    form.addEventListener('submit', (e) => {
      this.formSent = true;
      e.preventDefault();
      this.cardStart.emit();
      paymentForm.onSubmit(onSuccess, onError);
    });

    paymentForm.on('change', (data) => {
      this.formSent = false;
    });

    const ccFields = window.document.getElementsByClassName('payment-fields');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < ccFields.length; i++) {
      DomUtils.removeClass(ccFields[i], 'disabled');
    }

    this.subjectFormSubmit.subscribe(() => {
      this.formSent = true;
      this.cardStart.emit();
      paymentForm.onSubmit(onSuccess, onError);
    });
  }

  ngAfterViewInit(): void {
    this.initPaymentForm();
  }

  initPaymentForm() {
    this.config = this.paymentMsiService.getConfig();
    (window as any).firstdata.createPaymentForm(this.config, this.hooks, this.onCreate);
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.agreeWithDisclosureParent) {
          this.agreeWithDisclosure = changes.agreeWithDisclosureParent.currentValue;
      }
  }

  decline($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.declinePayment.emit();
  }

  ngOnDestroy(): void {
    if (this.paymentForm) { this.paymentForm.destroyFields(() => {}); }
  }
}
