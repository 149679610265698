import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PmaFormService {
  constructor() {}

  getFlagsAndSetForm(brandingInfo, firstForm, isPmaOptIn = false) {
    let nonpreferredAccountSelection = false;
    let nonpreferredAddressSelection = false;
    nonpreferredAccountSelection = brandingInfo.profile_attributes
      .some(item => item.name === 'nonpreferred_account_selection' && item.value);
    nonpreferredAddressSelection = brandingInfo.profile_attributes
      .some(item => item.name === 'nonpreferred_address_selection' && item.value);
    if (isPmaOptIn) {
      nonpreferredAccountSelection = false;
    }
    const pmaSingleHouses = brandingInfo.profile_attributes
      .some(item => item.name === 'pma_single_houses_mode' && item.value);
    if (pmaSingleHouses) {
      nonpreferredAddressSelection = true;
    }
    this.setFirstForm(firstForm, nonpreferredAccountSelection, nonpreferredAddressSelection, pmaSingleHouses);
    return { nonpreferredAccountSelection, nonpreferredAddressSelection, pmaSingleHouses};
  }

  setFirstForm(firstForm, nonpreferredAccountSelection, nonpreferredAddressSelection, pmaSingleHouses) {
    if (nonpreferredAccountSelection) {
      firstForm.addControl('pm_account', new FormControl('', Validators.required));
    }
    if (nonpreferredAddressSelection) {
      firstForm.addControl('address', new FormControl('', Validators.required));
      firstForm.addControl('county', new FormControl('', []));
      firstForm.addControl('neighborhood', new FormControl('', []));
      firstForm.removeControl('state');
    }
  }
}
