import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @Input() inputFormControl: FormControl;
  @Input() label = 'Upload Files';
  @Input() singleFile = false

  alerts: string[] = [] as string[];

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  validateFile(file) {
    const types = ['application/pdf', 'image/jpeg', 'image/png'];
    if (!types.find(type => type === file.type)) {
      const msg = 'Not Allowed File extension';
      this.alerts.push(file.name + ': ' + msg);
      return false;
    }
    if (file.size > 31457280) {
      const msg = 'Max file size is 30mb';
      this.alerts.push(file.name + ': ' + msg);
      return false;
    }
    return true;
  }

  onFileChange(event) {
    if (this.singleFile) {
      this.addSingleFile(event);
    } else {
      this.addMultipleFiles(event);
    }
  }

  addSingleFile(event) {
    if (event.target.files && event.target.files.length) {
      const files = event.target.files;
      const file = files[0];
      const currentFiles = [];
      if (this.validateFile(file)) {
        currentFiles.push(file);
      }
      this.inputFormControl.patchValue(currentFiles);
      this.cd.markForCheck();
    }
  }

  addMultipleFiles(event) {
    if (event.target.files && event.target.files.length) {
      const files = event.target.files;
      const currentFiles = this.inputFormControl.value;
      [...files].forEach(file => {
        if (this.validateFile(file)) {
          currentFiles.push(file);
        }
      });
      this.inputFormControl.patchValue(currentFiles);
      this.cd.markForCheck();
    }
  }

  removeFileByIndex(index) {
    const files = this.inputFormControl.value.filter((item, i) => i !== index);
    this.inputFormControl.patchValue(files);
  }

  removeAlertByIndex(index) {
    const alerts = this.alerts.filter((item, i) => i !== index);
    this.alerts = alerts;
  }

}
