import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GuaranteeFormUtils } from '../../../../../core/utils/guarantee.utils';
import {DateTime} from 'luxon';
import { EmailValidateService } from '../../../../../core/services/email-validate.service';

@Component({
  selector: 'pma-insured-user',
  templateUrl: './pma-insured-user.component.html',
  styleUrls: ['./pma-insured-user.component.scss']
})
export class PmaInsuredUserComponent implements OnInit, OnDestroy {
  @Input() form;
  @Input() emailControl;
  @Input() index;
  @Input() users;
  STATE_LIST = GuaranteeFormUtils.STATE_LIST;
  maxBirthdayDate;
  minBirthdayDate;
  subscription: Subscription = new Subscription();

  constructor(private emailValidateService: EmailValidateService) { }

  ngOnInit(): void {
    this.maxBirthdayDate = DateTime.local().minus({ years: 18 }).toJSDate();
    this.minBirthdayDate = DateTime.local().minus({ years: 65 }).toJSDate();

    this.onUserChanges();
    this.onEmailControlChanges();
  }

  onUserChanges() {
    this.subscription.add(
      this.form.valueChanges.subscribe(user => {
        if (this.isNotEmptyUser(user)) {
          this.setValidators();
          this.checkEmailsDifference();
        } else {
          this.clearValidators();
        }
      })
    )
  }

  onEmailControlChanges() {
    this.subscription.add(
      this.emailControl.valueChanges.subscribe(email => {
        if (email) {
          this.checkEmailsDifference();
        }
      })
    )
  }

  setValidators() {
    this.form.get('profile_attributes').get('first_name').setValidators([Validators.required, Validators.maxLength(30)]);
    this.form.get('profile_attributes').get('last_name').setValidators([Validators.required, Validators.maxLength(30)]);
    this.form.get('email').setValidators([Validators.required, Validators.email]);
    this.updateValueAndValidity();
  }

  updateValueAndValidity() {
    this.form.get('profile_attributes').get('first_name').updateValueAndValidity({ emitEvent: false });
    this.form.get('profile_attributes').get('last_name').updateValueAndValidity({ emitEvent: false });
    this.form.get('email').updateValueAndValidity({ emitEvent: false });
  }

  clearValidators() {
    this.form.get('profile_attributes').get('first_name').clearValidators();
    this.form.get('profile_attributes').get('last_name').clearValidators();
    this.form.get('email').clearValidators();
    this.form.reset({}, { emitEvent: false });
    this.form.get('spouse').reset(false, { emitEvent: false });
  }

  isNotEmptyUser(user) {
    if (user && user.email) {
      return true;
    }
    const profileAttributes = user.profile_attributes;
    const found = Object.keys(profileAttributes).find(key => profileAttributes[key]);
    return !!found;
  }

  removeUser(i: number): void {
    (this.users as FormArray).removeAt(i);
  }

  checkEmailsDifference() {
    this.emailValidateService.checkEmailsDifference(this.emailControl.value, this.users);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
