<ul [class.no-menu]="!isMenu">
  testing
  <li *ngFor="let item of links">
    <a class="cursor-pointer"
      [class.active-link]="activeUrl && activeUrl.includes(item.url)"
      (click)="item.url === '#logout' ? logout($event) : handleClick($event, item.url)">
      {{ item.title }}
    </a>
  </li>
</ul>
