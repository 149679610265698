import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailValidateService {

  constructor() { }

  checkEmailsDifference(mainEmail, users) {
    const rawValues = (users as any).getRawValue();
    const insuredEmails = rawValues.map(email => email && email.email ? email.email.toLowerCase() : null);
    const allEmails = [mainEmail.toLowerCase(), ...insuredEmails];
    const emailControls = users.controls.map(control => (control as any).controls['email']);
    for (const emailControl of emailControls) {
      const currentEmail = emailControl && emailControl.value ? emailControl.value.toLowerCase() : null;
      const sameEmails = allEmails.reduce((acc, value) => acc + Number(value === currentEmail), 0);
      if (sameEmails > 1) {
        emailControl.setErrors({
          sameEmail: {
            valid: false
          }
        });
      } else {
        this.removeSameEmailErrorIfExists(emailControl);
      }
    }
  }

  private removeSameEmailErrorIfExists(emailControl) {
    const errors = emailControl.errors;
    if (errors && errors.sameEmail) {
      delete errors.sameEmail;
      if (Object.keys(errors).length > 0) {
        emailControl.setErrors({
          ...errors
        });
      } else {
        emailControl.setErrors(null);
      }
    }
  }
}
