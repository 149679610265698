<div style="padding-top: 20px;">
  <ng-container *ngFor="let user of form.get('users')['controls']; let i = index;">
    <pma-insured-user
      [form]="user"
      [users]="form.get('users')"
      [emailControl]="form.get('email')"
      [index]="i"
    ></pma-insured-user>
  </ng-container>

  <button
    type="button"
    mat-button
    class="mat-btn tenant-insured-btn"
    (click)="addUser()">
    + {{ 'residential.add_insured_button' | translate }}
  </button>

</div>
