import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {ResidentialService} from '../core/services/residential.service';
import {Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import { Insurable } from '../core/models/insurable.model';

export const GCPolicyUploadDefaultAddress = ['82 Nassau Street, DPT 33028', 'New York, NY 10038'];
export const GCPolicyUploadNewAddress = ['PO Box 660121', 'Dallas, TX 75266'];

/**
 * These values map to BrandingProfile.profile_attributes
 */
export interface BrandingAdditionalInterest {
  /**
   * profile_attributes.additional_interest_name
   */
  name?: string;
  /**
   * profile_attributes.additional_interest_address_part_1
   */
  address_part_1: string;
  /**
   * profile_attributes.additional_interest_address_part_2
   */
  address_part_2?: string;
}


@Injectable({
  providedIn: 'root'
})
export class PmaService {
  constructor(private httpClient: HttpClient,
              private router: Router) {}

  static getTypeIdsByName(name) {
    const typeIds = {
      communities: [1, 2, 3],
      buildings: [7],
      units: [4, 5]
    };

    return typeIds[name];
  }

  static getLiabilityCoverages() {
    return [
      10000000,
      20000000,
      30000000,
      40000000,
      50000000,
      100000000
    ];
  }

  getTenantDataFromToken(id) {
    return this.httpClient.get<Insurable>(`/insurables/${id}`);
  }

  getStatesByBrandingId(id) {
    return this.httpClient.get(`/communities/account_states/${id}`);
  }

  getCommunitiesByState(state, brandingId) {
    return this.httpClient.get<Insurable[]>(`/communities/by_state/${state}`, { params: { branding_id: brandingId } } );
  }

  searchCommunities(state, search, branding_id) {
    const limit = 50;
    const params = {
      search,
      branding_id,
      limit
    }
    return this.httpClient.get(`/communities/by_state/${state}`,  { params });
  }

  getAdditionalInterestByInsurableId(id) {
    return this.httpClient.get(`/additional_interest_name_usage/${id}`);
  }

  setAdditionalInterestAddress(community, communityTitle) {
    let additionalInterestName = '';

    if (community?.account?.additional_interest) {
      additionalInterestName = community?.account?.additional_interest_name ? community?.account?.additional_interest_name : community?.account?.title;
    }

    if (community?.additional_interest) {
      additionalInterestName = community?.additional_interest_name ? community?.additional_interest_name : communityTitle;
    }

    if (!additionalInterestName) {
      additionalInterestName = communityTitle ? communityTitle : community?.account?.title ? community?.account?.title : '';
    }

    return additionalInterestName;
  }

  setAdditionalInterestName(community, communityTitle) {
    return community?.additional_interest ? communityTitle : community?.account?.title;
  }

  getMasterPolicyByInsurableId(id) {
    return this.httpClient.get(`/policies/master_policy_unit_coverage/${id}`);
  }

  getInsurableByAuthToken(token) {
    return this.httpClient.get(`/insurable_by_auth_token?token=${token}`);
  }

  getLocationByLatLong(latitude, longitude) {
    return this.httpClient.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDGRctiNjYgcsN348nrYU7Z1yYK4vJkeNs`);
  }

  navigateToResidential(form, address, county, addressAttributes = null, unitControl = null, communityControl?: FormControl, buildingControl?: FormControl) {
    const RENTERS_LOCAL_STORAGE_KEY = ResidentialService.RENTERS_LOCAL_STORAGE_KEY;
    const body =
      JSON.parse(localStorage.getItem(RENTERS_LOCAL_STORAGE_KEY)) || {};

    body.first_name = form.get('first_name').value;
    body.last_name = form.get('last_name').value;
    body.middle_name = form.get('middle_name').value;
    body.email = form.get('email').value;
    body.address = address;
    if (form.get('pm_account')) {
      body.pm_account = form.get('pm_account').value;
    }
    if (communityControl && communityControl.value && communityControl.value.id) {
      body.community = communityControl.value;
    }
    if (buildingControl && buildingControl.value && buildingControl.value.id) {
      body.building = buildingControl.value;
    }
    if (form.get('state') && form.get('state').value) {
      body.state = form.get('state').value;
    }
    if (form.get('unit') && form.get('unit').value) {
      body.unit = form.get('unit').value;
    }
    if (county) {
      localStorage.setItem('RENTERS_COUNTY', county);
    }
    if (form.get('county') && form.get('county').value) {
      localStorage.setItem('RENTERS_COUNTY', form.get('county').value);
    }
    if (form.get('neighborhood') && form.get('neighborhood').value) {
      localStorage.setItem('RENTERS_NEIGHBORHOOD', form.get('neighborhood').value);
    }
    if (addressAttributes) {
      this.setAddressAttributes(body, addressAttributes);
    }
    localStorage.setItem(RENTERS_LOCAL_STORAGE_KEY, JSON.stringify(body));
    this.router.navigateByUrl('residential');
  }

  setAddressAttributes(body, addressAttributes) {
    if (addressAttributes.street_name) {
      body.street_name = addressAttributes.street_number + ' ' + addressAttributes.street_name
    }
    if (addressAttributes.street_two) {
      body.street_two = addressAttributes.street_two
    }
    if (addressAttributes.zip_code) {
      body.postal_code = addressAttributes.zip_code
    }
    if (addressAttributes.state) {
      body.state = addressAttributes.state
    }
  }

  enrollMasterPolicy(body) {
    return this.httpClient.post('/issuer/enroll_child_policy', body);
  }

  addCoverageProof(body) {
    return this.httpClient.post('/policies/external/upload', body);
  }

  getAdditionalInterest(brandingProfileAttributes): BrandingAdditionalInterest {
    return {
      name: brandingProfileAttributes.find(item => item.name === 'additional_interest_name')?.value || '',
      address_part_1: brandingProfileAttributes.find(item => item.name === 'additional_interest_address_part_1')?.value || '',
      address_part_2: brandingProfileAttributes.find(item => item.name === 'additional_interest_address_part_2')?.value || '',
    };
  }

  getCarriers() {
    let params = new HttpParams();
    params = params.append('per_page', '10000');
    params = params.append('page', '1');

    return this.httpClient.get(`/carriers`, { params });
  }

  getMatchUsers(firstName, lastName, email) {
    const body = {
      first_name: firstName,
      last_name: lastName,
      email
    }
    return this.httpClient.post(`/users/matching`,  body);
  }

  searchAddresses(search, branding_id) {
    const limit = 50;
    const payload = {
      search,
      branding_id,
      limit
    }
    return this.httpClient.post(`/communities/search`, payload);
  }
}
