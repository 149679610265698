import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PmaTokenService {
  constructor() {}

  setGeneralData(form, communityControl, nonpreferredAddressSelection, payload) {
    form.get('first_name').setValue(payload.user_attributes.first_name);
    form.get('last_name').setValue(payload.user_attributes.last_name);
    form.get('email').setValue(payload.user_attributes.email);

    const addressAttributes = payload && payload.addresses_attributes && payload.addresses_attributes[0] ? payload.addresses_attributes[0] : {};
    if (!nonpreferredAddressSelection) {
      form.get('state').setValue(addressAttributes.state, { emitEvent: false });
    }
    const fullAddress = addressAttributes.full;
    if (nonpreferredAddressSelection) {
      form.get('address').setValue(fullAddress, { emitEvent: false });
    }
    communityControl.setValue({ id: payload.id, title: payload.title }, { emitEvent: false });

    form.get('unit').enable();

    // const primaryInsurableAttributes = payload && payload.primary_insurable_attributes ? payload.primary_insurable_attributes : {};
    // const building = primaryInsurableAttributes && primaryInsurableAttributes.building ? primaryInsurableAttributes.building : {};
    // const unit = primaryInsurableAttributes && primaryInsurableAttributes.unit ? primaryInsurableAttributes.unit : {};
    // setTimeout(() => {
    //   buildingControl.setValue({ id: building.id, title: building.title, units: building.units }, { emitEvent: false });
    //   setTimeout(() => {
    //     form.get('unit').setValue({ id: unit.id, title: unit.title  });
    //   }, 500);
    // }, 500);
  }
}
