 <div [formGroup]="form" class="pma-insured">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div class="user-entity-form__title" style="margin-bottom: 24px;">{{ 'residential.insured_detail' | translate }}</div>
    <button mat-button color="warn" type="button" (click)="removeUser(index)">{{ 'residential.remove_insured_button' | translate | uppercase }}</button>
  </div>

  <div style="margin-bottom: 12px;">
    <mat-checkbox formControlName="spouse" color="primary">Spouse</mat-checkbox>
  </div>

  <div>
    <ng-container formGroupName="profile_attributes">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="" appearance="outline">
          <mat-label>{{ 'form.first_name' | translate }}</mat-label>
          <input matInput formControlName="first_name">
          <mat-error *ngIf="form['controls'].profile_attributes['controls'].first_name.invalid">
            {{ form['controls'].profile_attributes['controls'].first_name.errors | errorMsg:form['controls'].profile_attributes['controls'].first_name }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="" appearance="outline">
          <mat-label>{{ 'user.middle_name' | translate }}</mat-label>
          <input matInput formControlName="middle_name">
          <mat-error *ngIf="form['controls'].profile_attributes['controls'].middle_name.invalid">
            {{ form['controls'].profile_attributes['controls'].middle_name.errors | errorMsg:form['controls'].profile_attributes['controls'].middle_name }}
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>
  </div>

  <div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">

      <ng-container formGroupName="profile_attributes">
        <mat-form-field fxFlex="" appearance="outline">
          <mat-label>{{ 'form.last_name' | translate }}</mat-label>
          <input matInput formControlName="last_name">
          <mat-error *ngIf="form['controls'].profile_attributes['controls'].last_name.invalid">
            {{ form['controls'].profile_attributes['controls'].last_name.errors | errorMsg:form['controls'].profile_attributes['controls'].last_name }}
          </mat-error>
        </mat-form-field>
      </ng-container>

      <mat-form-field fxFlex="" appearance="outline">
        <mat-label>{{ 'form.email' | translate }}</mat-label>
        <input matInput formControlName="email">
        <mat-error *ngIf="form['controls'].email.invalid">{{ form['controls'].email | errorMsg:'Email' }}</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
