import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormArray, FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ExternalPolicyFillService {
  constructor(private httpClient: HttpClient,
              private fb: FormBuilder) {}

  externalPolicyCheck(number, email) {
    const payload = {
      policy: { number, email }
    };
    return this.httpClient.post(`/policies/external/check`, payload);
  }

  prefillForm(form,
              liabilityCoverageControl, contentCoverageControl,
              payload) {
    console.log(form, payload);
    const carrier = payload && payload.carrier && payload.carrier.id ? payload.carrier : null;
    if (carrier) {
      form.get('out_of_system_carrier_title').setValue({ id: payload.carrier.id, title: payload.carrier.title });
    }
    // const effectiveDate = payload && payload.policy && payload.policy.effective_date ? payload.policy.effective_date : null;
    // if (effectiveDate) {
    //   form.get('effective_date').setValue(effectiveDate, { emitEvent: false });
    // }
    // const expirationDate = payload && payload.policy && payload.policy.expiration_date ? payload.policy.expiration_date : null;
    // if (expirationDate) {
    //   form.get('expiration_date').setValue(expirationDate, { emitEvent: false });
    // }
    this.setPrimaryUser(form, payload);
    this.setSecondaryUsers(form);
    this.setCoverages(liabilityCoverageControl, contentCoverageControl, payload);
  }

  setPrimaryUser(form, payload) {
    const firstUser = payload && payload.users && payload.users[0] ? payload.users[0] : null;
    if (firstUser) {
      if (firstUser.email) {
        form.get('email').patchValue(firstUser.email, { emitEvent: false });
      }
      const profile = firstUser && firstUser.profile ? firstUser.profile : null;
      if (profile && profile.first_name) {
        form.get('first_name').patchValue(profile.first_name, { emitEvent: false });
      }
      if (profile && profile.last_name) {
        form.get('last_name').patchValue(profile.last_name, { emitEvent: false });
      }
      if (profile && profile.middle_name) {
        form.get('middle_name').patchValue(profile.middle_name, { emitEvent: false });
      }
    }
  }

  setSecondaryUsers(form) {
    const users = (form.get('users') as FormArray)
    users.clear();
  }

  setCoverages(liabilityCoverageControl, contentCoverageControl, payload) {
    const coverageRequirements = payload && payload.coverage_requirements ? payload.coverage_requirements : null;
    if (coverageRequirements && coverageRequirements.length > 0) {
      const liability = coverageRequirements.find(item => item.designation === 'liability');
      if (liability && liability.amount) {
        liabilityCoverageControl.setValue(liability.amount);
      }
    }
    const policyCoverages = payload && payload.policy_coverages ? payload.policy_coverages : null;
    if (policyCoverages && policyCoverages.length > 0) {
      const contents = policyCoverages.find(item => item.designation === 'contents');
      if (contents && contents.limit) {
        contentCoverageControl.setValue(contents.limit / 100);
      }
    }
  }
}
