import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'pma-insured',
  templateUrl: './pma-insured.component.html',
  styleUrls: ['./pma-insured.component.css']
})
export class PmaInsuredComponent implements OnInit {
  @Input() form: FormGroup;

  constructor(private fb: FormBuilder,
              private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  addUser() {
    const users = (this.form.get('users') as FormArray);
    users.push(
      this.fb.group({
        email: ['', [Validators.email]],
        spouse: false,
        profile_attributes: this.fb.group({
          first_name: ['', [Validators.maxLength(30)]],
          last_name: ['', [Validators.maxLength(30)]],
          middle_name: ['', [Validators.maxLength(25)]],
        })
      })
    );
    this.cd.detectChanges();
  }
}
