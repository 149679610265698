import { Component, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import { filter, first, map, skipWhile, switchMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { BrandingService } from './core/services/branding.service';
import { detect } from 'detect-browser';
import { AppService } from './core/services/app.service';
import { MetadataService } from './metadata.service';
import { isPlatformBrowser } from '@angular/common';
import { SwitchLanguagesService } from './shared/layout/header/switch-languages/switch-languages.service';
import { IframeModeService } from './core/services/iframe-mode.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'GetCovered Client';
  subscription: Subscription = new Subscription();
  isUserUrl = false;
  showBg = false;
  notSupportedPage = false;
  customRgPage;
  mainContentClass;
  iframeMode = false;

  constructor(
    public brandingService: BrandingService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private switchLanguagesService: SwitchLanguagesService,
    private IframeModeService: IframeModeService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() private metadataService: MetadataService
  ) {
    this.translate.setDefaultLang('en');
    this.brandingService.customRgPage$.subscribe(customRgPage => {
      this.customRgPage = customRgPage;
      if (this.customRgPage === 'freeway') {
        this.mainContentClass = 'main-content_freeway';
      }
    });

    if (this.metadataService) {
      const image = this.metadataService.getValueByField('og:image');
      const title = this.metadataService.getValueByField('og:title');
      this.metadataService.updateMetadata(title, image);
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.initCookieConsent();
    }

    if (this.isOldBrowsers()) {
      this.router.navigateByUrl('not-supported');
    }

    this.subscription.add(this.router.events
      .pipe(filter(value => value instanceof NavigationEnd))
      .subscribe((value: any) => {
        this.showBg = value.url.includes('residential') || value.url.includes('upload-coverage-proof')
        || value.url.includes('tenant') || value.url.includes('deposit-choice');
        this.notSupportedPage = value.url.includes('not-supported');
        this.isUserUrl = (value.url === value.urlAfterRedirects ) ? value.url.includes('user') : false;
    }));

    this.brandingService.fetchBrandingInfo();

    combineLatest([this.router.events.pipe(
        filter(e => e instanceof NavigationEnd)
    ), this.brandingService.gaTrackingId$.pipe(
        skipWhile(trackingId => trackingId === null),
    ), this.brandingService.brandingInfo$.pipe(
        skipWhile(brandingInfo => Object.keys(brandingInfo).length === 0)
    )]).pipe(
        first()
    ).subscribe((result) => {
        // window.analytics.page(result[0]);
        this.includeGa(result[0], result[1]);
    });

    const navigationSub = this.appService.isGaLoaded$.pipe(
      skipWhile((isGaLoaded) => !isGaLoaded),
      switchMap(() => this.router.events),
      filter(e => e instanceof NavigationEnd)
    ).subscribe((routerEvent) => {
        if (routerEvent instanceof RouterEvent) {
          // window.analytics.page(routerEvent.url);
          window.gtag('config', 'GA_MEASUREMENT_ID', {page_path: routerEvent.url});
        }
    });
    this.subscription.add(navigationSub);

    const iframeInit = this.route.queryParams.pipe(
      filter(params => params['access'] && params['access'] === 'iframe'),
      map(params => params['access']),
    ).subscribe(() => {
      this.iframeMode = true;
      this.IframeModeService.setIframeMode(true);
    });
    this.subscription.add(iframeInit);
  }

  private isOldBrowsers() {
    const browser = detect();
    if (browser.name === 'ie' || browser.name === 'opera-mini') {
      return true;
    } else if (browser.name === 'edge' && parseInt(browser.version, 10) < 16) {
      return true;
    } else  if (browser.name === 'chrome' && parseInt(browser.version, 10) < 49) {
      return true;
    } else if (browser.name === 'firefox' && parseInt(browser.version, 10) < 31) {
      return true;
    } else if (browser.name === 'safari' && parseInt(browser.version, 10) < 10) {
      return true;
    } else if (browser.name === 'opera' && parseInt(browser.version, 10) < 36) {
      return true;
    }
    return false;
  }

  includeGa(url, brandingTrackingId): void {
    const trackingId = brandingTrackingId !== '' ? brandingTrackingId : '122849008-1';
    const trackingCodes = trackingId.split(',');

    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + trackingId;
    script.async = true;
    document.head.appendChild(script);

    const scriptInline = document.createElement('script');
    scriptInline.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    `;

    for (const trackingCode of trackingCodes) {
      scriptInline.text += `
        gtag('config', '${trackingCode.trim()}')
      `;
    }

    document.head.appendChild(scriptInline);
    this.sendGaEvents(url);
  }

  sendGaEvents(url): void {
    if (typeof window.gtag === 'function') {
      this.appService.setIsGaLoaded(true);
      window.gtag('config', 'GA_MEASUREMENT_ID', {page_path: url});
    } else {
      setTimeout(this.sendGaEvents.bind(this), 250);
    }
  }

  loadEcommerce(): void {
    window.ga('require', 'ecommerce');
  }

  initCookieConsent() {
    // @ts-ignore
    const cookieconsent = window.cookieconsent;
    cookieconsent.initialise({
      palette: {
        popup: {
          background: '#717171',
          text: 'white'
        },
        button: {
          background: 'white',
          text: '#717171'
        }
      },
      theme: 'classic',
      type: 'opt-out',
      content: {
        link: 'Privacy Policy',
        href: 'https://www.getcoveredinsurance.com/privacy-policy',
        message: 'This site uses cookies to help us deliver the best experience possible. To learn more, please see our'
      },
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
