import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
@Component({
  selector: 'app-faq-question',
  templateUrl: './faq-question.component.html',
  styleUrls: ['./faq-question.component.css'],
})
export class FaqQuestionComponent implements OnInit {

  @ViewChild('accordion', {static: true}) accordion: MatAccordion;
  @Input() question;
  @Input() answer;
  @Input() search;

  constructor() {}

  ngOnInit(): void {}

  openAll() {
    this.accordion.openAll();
  }

  closeAll() {
    this.accordion.closeAll();
  }

}
