<ng-container *ngIf="pmaSingleHouses">
  <ui-single-house-address
    [form]="form"
    (setAccountId)="emitAccountId($event)"
    (setCommunity)="setCommunity($event)"
    (emitAddressAttributes)="emitAddressAttributes($event)"
  ></ui-single-house-address>
</ng-container>

<ng-container *ngIf="!pmaSingleHouses">
  <ng-container *ngIf="!nonpreferredAddressSelection">
    <p class="section-title color_primary">{{ "tenant_boarding.choose_your_state" | translate }}</p>
    <div>
      <mat-form-field fxFlex="100%" appearance="outline">
        <mat-label>{{ 'form.state' | translate }}</mat-label>
        <mat-select [formControl]="form.get('state')" id="state" required>
          <mat-option *ngFor="let state of stateList;" [value]="state">{{state}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <div *ngIf="!nonpreferredAddressSelection; else addressSelection">
    <div>
      <p
        class="section-title color_primary">{{ isBrandingSecondNature ? 'Choose Property' : 'Choose Community' }}</p>
      <div>
        <ui-community
          [stateControl]="form.get('state')"
          [communityControl]="communityControl"
          [unitControl]="form.get('unit')"
          [brandingId]="brandingId"
        ></ui-community>
      </div>

      <div *ngIf="isBuildingControl">
        <p class="section-title color_primary">Choose Building</p>
        <div>
          <ui-building
            [buildings]="buildings"
            [buildingControl]="buildingControl"
            [unitControl]="form.get('unit')"
            [loader]="loader"
          ></ui-building>
        </div>
      </div>

    <div>
      <mat-error
        class="error-row"
        *ngIf="form.get('unit').touched && !units && !isCommunity">Please select a Community</mat-error>
      <p class="section-title color_primary">{{ "tenant_boarding.choose_your_unit" | translate }}</p>
      <div>
        <ui-unit
          [unitControl]="form.get('unit')"
          [units]="units"
          [loader]="loader"
        ></ui-unit>
      </div>
    </div>
  </div>
</div>
<ng-template #addressSelection>
  <div class="pma-address-wrapper" fxLayout="column">

    <ui-address-smarty
        [form]="form"
        (setBuildingsFromAddress)="setBuildingsFromAddress($event)"
        (setUnitsFromAddress)="setUnitsFromAddress($event)"
        (setAccountId)="emitAccountId($event)"
        (setCommunity)="setCommunity($event)"
      ></ui-address-smarty>

      <div *ngIf="isBuildingControl && !isBuildingAddress">
        <p class="section-title color_primary">Choose Building</p>
        <div>
          <ui-building
            [buildings]="buildings"
            [buildingControl]="buildingControl"
            [unitControl]="form.get('unit')"
          ></ui-building>
        </div>
      </div>

      <div *ngIf="isBuildingCheckbox" class="building-checkbox">
        <mat-checkbox
          [formControl]="buildingAddressCheckbox"
          color="primary"
          labelPosition="after"
          class="checkbox-layout-custom"
          (click)="$event.stopPropagation()">
          Community consists of several buildings
        </mat-checkbox>
      </div>

      <div *ngIf="isBuildingAddress">
        <div>
          <ui-building-address
            [buildingAddressControl]="buildingAddressControl"
            [unitControl]="form.get('unit')"
            [communityId]="fetchedCommunity && fetchedCommunity.id"
            (setBuilding)="setBuilding($event)"
          ></ui-building-address>
        </div>
      </div>

      <div>
        <p class="section-title color_primary">{{ "tenant_boarding.choose_your_unit" | translate }}</p>
        <div>
          <ui-unit
            [unitControl]="form.get('unit')"
            [units]="units"
            [nonApplicableControl]="nonApplicableControl"
          ></ui-unit>
        </div>
      </div>
    </div>
  </ng-template>

</ng-container>
