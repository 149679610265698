<div class="file-dnd">
  <span class="file-dnd__choose">Choose Files</span>
  <span class="file-dnd__msg">
    <b>{{ label }}</b><br>
    Supported File Types: PDF, JPEG, PNG
  </span>
  <input class="file-dnd__input"
         type="file"
         (change)="onFileChange($event)"
         accept=".pdf, .jpeg, .jpg, image/png"
         [multiple]="!singleFile">
</div>

<mat-error *ngIf="inputFormControl.invalid && inputFormControl.touched" style="margin-top: 10px;">
  Upload at least one file
</mat-error>

<div *ngIf="alerts && alerts.length > 0" style="margin-top: 10px;">
  <mat-error *ngFor="let alert of alerts; index as i" style="margin-top: 5px;">
    <div fxFlex="" fxLayoutAlign="space-between center">
      <span>{{ alert }}</span>
      <mat-icon class="cursor-pointer" mat-list-icon (click)="removeAlertByIndex(i)">close</mat-icon>
    </div>
  </mat-error>
</div>

<div style="margin-top: 20px;">
  <div style="margin-bottom: 20px;" *ngFor="let file of inputFormControl.value; index as i">
    <div fxFlex="" fxLayoutAlign="start center">
      <span class="file-dnd__filename">{{ file.name }} ({{ file.size | fileMb }} MB)</span>
      <mat-icon style="margin-bottom: 5px;" class="cursor-pointer" color="warn" mat-list-icon (click)="removeFileByIndex(i)">delete</mat-icon>
    </div>
  </div>
</div>
