<div class="tenant-boarding__header tenant-boarding__header_small">
  <ng-container *ngIf="!isBrandingSecondNature; else secondNatureHeader">
    {{ 'tenant_boarding.already_covered' | translate }}?
    <span class="color_primary">&nbsp;{{ 'tenant_boarding.upload_your_proof_of_coverage' | translate }}</span>
  </ng-container>
  <ng-template #secondNatureHeader>{{ 'tenant_boarding.upload_your_own_policy' | translate }}</ng-template>
</div>

<div class="tenant-boarding__wrap">
  <div *ngIf="isGetQuote" class="tenant-boarding__wrap-get-renters-quote">
    <div class="tenant-boarding__get-renters-quote">
      <p>Get a renters insurance quote in just a few clicks</p>
      <button mat-button
              type="button"
              class="mat-btn tenant-btn"
              (click)="navigateToRentersInsurance()">
        Get Quote Now
      </button>
    </div>
  </div>

  <div class="tenant-boarding__cloud-container">
    <p *ngIf="!isBrandingSecondNature && brandingId !== 2698 && pmAccountName !== 'Essex'" class="tenant-boarding__text tenant-boarding__text tenant-boarding-partnered-text">
      {{ pmAccountName }} has partnered with Get Covered Insurance to
      offer residents an easy way to satisfy their insurance requirements. Purchase insurance or upload your policy in
      less than a minute.
    </p>
    <ng-container *ngIf="pmAccountName === 'Essex'">
      <div class="tenant-boarding__text tenant-boarding__text tenant-boarding-partnered-text">
        Please complete the below form and upload a copy of your insurance declaration page meeting the minimum requirements below:
        <ul style="list-style: inside;margin-top:15px;margin-left: 30px;margin-bottom:15px;">
          <li>Insurance policy <strong>must</strong> include all leaseholders</li>
          <li>Minimum insurance coverage: $300,000</li>
          <li>Additional Interest: Essex: PO Box 660121, Dallas, TX, 75266</li>
          <li>Policy is purchased through an insurance carrier with an AM Best or A-VII Rating</li>
        </ul>
        <strong>Any questions or concerns regarding your renters insurance compliance please email us at <a href="mailto:Essex@policyverify.io">Essex@policyverify.io</a>.</strong>
      </div>
    </ng-container>

    <form class="tenant-boarding__form" [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="pma-row">
        <div fxLayout="row" fxLayoutGap="10px" fxLayout.lt-sm="column">
          <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%" appearance="outline">
            <mat-label>{{ 'user.first_name' | translate }}</mat-label>
            <input matInput type="text" formControlName="first_name" required>
            <mat-error *ngIf="f['first_name'].errors">{{ f['first_name'] | errorMsg }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%" appearance="outline">
            <mat-label>{{ 'user.middle_name' | translate }}</mat-label>
            <input matInput type="text" formControlName="middle_name">
            <mat-error *ngIf="f['middle_name'].errors">{{ f['middle_name'] | errorMsg }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="pma-row">
        <div fxLayout="row" fxLayoutGap="10px" fxLayout.lt-sm="column">
          <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%" appearance="outline">
            <mat-label>{{ 'user.last_name' | translate }}</mat-label>
            <input matInput type="text" formControlName="last_name" required>
            <mat-error *ngIf="f['last_name'].errors">
              {{ f['last_name'] | errorMsg }}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%" appearance="outline">
            <mat-label>{{ 'residential.email_address' | translate }}</mat-label>
            <input matInput type="text" formControlName="email" required>
            <mat-error *ngIf="f['email'].errors">{{ f['email'] | errorMsg }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div>

        <ui-insurables
          [nonpreferredAddressSelection]="nonpreferredAddressSelection"
          [pmaSingleHouses]="pmaSingleHouses"
          [form]="firstForm"
          [nonApplicableControl]="nonApplicableControl"
          [communityControl]="communityControl"
          [buildingControl]="buildingControl"
          [buildingAddressControl]="firstForm.get('building_address')"
          [buildingAddressCheckbox]="firstForm.get('building_address_checkbox')"
          (setFullAddress)="setFullAddress($event)"
          (setAddressAttributes)="setUserAddressAttributes($event)"
        ></ui-insurables>

        <pm-account *ngIf="nonpreferredAccountSelection" [accountControl]="firstForm.get('pm_account')"></pm-account>
      </div>


      <div *ngIf="inTenantBoarding || isValidInitForm">
        <div matTooltipPosition="above"
             matTooltip="Only PDF, JPG or PNG file types are accepted">
          <app-file-upload label="Upload Declaration Page"
                           [singleFile]="true"
                           [inputFormControl]="form.get('documents')"></app-file-upload>
        </div>

        <div class="pma-row" style="margin-bottom: 27px">
          <div fxFlex="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="" appearance="outline">
              <mat-label>{{ 'tenant_boarding.lease_start' | translate }}</mat-label>
              <input matInput required formControlName="lease_start"
                     [matDatepicker]="leaseStart"
                     [min]="minDate"
              >
              <mat-datepicker-toggle matSuffix [for]="leaseStart"></mat-datepicker-toggle>
              <mat-datepicker #leaseStart></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex="" appearance="outline">
              <mat-label>{{ 'user.policy_number' | translate }}</mat-label>
              <input matInput type="text" formControlName="number" required>
              <mat-error *ngIf="f['number'].errors">{{ f['number'] | errorMsg }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="pma-row">
          <div fxFlex="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="" appearance="outline">
              <mat-label>{{ 'tenant_boarding.policy_start' | translate }}</mat-label>
              <input matInput required formControlName="effective_date"
                     [matDatepicker]="effectiveDate"
                     (dateInput)="updateDate($event)"
                     (dateChange)="updateDate($event)"
                     [min]="minDate"
              >
              <mat-datepicker-toggle matSuffix [for]="effectiveDate"></mat-datepicker-toggle>
              <mat-datepicker #effectiveDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex="" appearance="outline">
              <mat-label>{{ 'tenant_boarding.expiration' | translate }}</mat-label>
              <input matInput formControlName="expiration_date"
                     [matDatepicker]="expirationDate"
                     [min]="minDate"
                     [max]="maxDate"
              >
              <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
              <mat-datepicker #expirationDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="carrier-margin">
          <pma-carrier
            [carrierControl]="form.get('out_of_system_carrier_title')"
            [synonyms]="synonyms"
            (setSynonyms)="setSynonyms($event)"
          ></pma-carrier>
        </div>

        <div class="pma-row">
          <div fxFlex="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%" appearance="outline">
              <mat-label>{{ 'tenant_boarding.liability_coverage' | translate }}</mat-label>
              <mat-select [formControl]="liabilityCoverageControl" required>
                <mat-option *ngFor="let item of liabilityCoverages" [value]="item">
                  {{ item | stripeCurrency }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="liabilityCoverageControl.errors && communityLiabilityCoverageMin">Minimum coverage is {{ communityLiabilityCoverageMin | stripeCurrency }}</mat-error>
            </mat-form-field>



            <mat-form-field fxFlex="50%" fxFlex.lt-sm="100%" appearance="outline">
              <mat-label>{{ 'tenant_boarding.contents_coverage' | translate }}</mat-label>
              <input matInput
                     type="text"
                     prefix="$"
                     mask="separator"
                     thousandSeparator=","
                     maxlength="12"
                     [formControl]="contentsCoverageControl">
              <mat-error *ngIf="contentsCoverageControl.errors && communityContentsCoverageMin">Minimum coverage is {{ communityContentsCoverageMin | stripeCurrency }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="pma-row">
          <mat-checkbox formControlName="additional_interest"
                        color="primary"
                        labelPosition="before"
          >
          <span>
            Is
            <span class="tenant-boarding__highlight" *ngIf="!selectedPmAccount; else selectedPmAccountName">
              {{ additionalInterest.name ? additionalInterest.name : getCommunityAdditionalInterestName }}
            </span>
            <ng-template #selectedPmAccountName>{{selectedPmAccount.title}}</ng-template>
            listed as an <span class="tenant-boarding__text_blue" (click)="openAdditonalInterestDialog()">additional interest</span> on your policy?*
          </span>
          </mat-checkbox>
        </div>

        <div *ngIf="showAdditionalEmailNotice" class="pma-row additional-email">
          <div fxLayout="row"
               fxLayoutGap="2px"
               fxLayoutAlign="start start">
            <div>
              <mat-icon>warning</mat-icon>
            </div>
            <div>
              <div class="label">
                Additional Interest Email:
              </div>
              <div>
                If your carrier does not physically mail notices, please be sure to include
                insurancesupport@secondnature.com along with the additional interest address below.
              </div>
            </div>
          </div>
        </div>

        <div class="pma-row">
          <mat-error fxLayout="row"
                     fxLayoutGap="2px"
                     fxLayoutAlign="start center">
            <mat-icon class="error-icon">info</mat-icon>
            <span>Additional Interest Address:</span>
          </mat-error>
          <div style="font-size: 14px; margin: 5px 0 0 25px;">
            <ng-container *ngIf="selectedPmAccount"><div class="tenant-boarding__highlight">{{selectedPmAccount.title}}</div></ng-container>
            <ng-container *ngIf="!selectedPmAccount">
              <ng-container *ngIf="additionalInterest.name; else legacyAccountName">
                <div class="tenant-boarding__highlight">
                  {{ additionalInterest.name }}
                </div>
              </ng-container>
              <ng-template #legacyAccountName>
                <div class="tenant-boarding__highlight">{{ getAdditionalInterestAddress }}</div>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="!selectedPmAccount; else addressFromAccount">
              <ng-container *ngIf="additionalInterest.address_part_1 && additionalInterest.address_part_2; else legacyAddress">
                <div class="tenant-boarding__highlight">{{additionalInterest.address_part_1}}</div>
                <div class="tenant-boarding__highlight">{{additionalInterest.address_part_2}}</div>
              </ng-container>
              <ng-template #legacyAddress>
                <ng-container *ngIf="[303].includes(branding.id) || isBrandingSecondNature || brandingSlug === 'garden_communities'; else oldAddress">
                  <div class="tenant-boarding__highlight">{{GCPolicyUploadNewAddress[0]}}</div>
                  <div class="tenant-boarding__highlight">{{GCPolicyUploadNewAddress[1]}}</div>
                </ng-container>
                <ng-template #oldAddress>
                  <div class="tenant-boarding__highlight">{{GCPolicyUploadDefaultAddress[0]}}</div>
                  <div class="tenant-boarding__highlight">{{GCPolicyUploadDefaultAddress[1]}}</div>
                </ng-template>
              </ng-template>
            </ng-container>
            <ng-template #addressFromAccount>
              <ng-container *ngIf="selectedPmAccount.address.street_number &&
              selectedPmAccount.address.street_name &&
              selectedPmAccount.address.city &&
              selectedPmAccount.address.state &&
              selectedPmAccount.address.zip_code
              ">
                <div class="tenant-boarding__highlight"> {{selectedPmAccount.address.street_number}} {{selectedPmAccount.address.street_name}},</div>
                <div class="tenant-boarding__highlight">{{selectedPmAccount.address.city}}, {{selectedPmAccount.address.state}} {{selectedPmAccount.address.zip_code}}</div>
              </ng-container>
            </ng-template>
          </div>
        </div>

        <div style="font-weight: 400;">
          Is your spouse or roommate included on the policy?
        </div>

        <pma-insured [form]="form"></pma-insured>

        <div *ngIf="isBrandingSecondNature && slug !== 'pinata'" style="margin: 15px 0;">
          <mat-checkbox [formControl]="dogCoverageControl"
                        color="primary"
                        labelPosition="before"
          >
            Do you have a dog? If yes, do you have dog bite coverage included in your policy?
          </mat-checkbox>
        </div>

        <div *ngIf="form.get('additional_interest').touched && !form.get('additional_interest').value" style="margin: 20px 0 10px;">
          <mat-error fxLayout="row"
                     fxLayoutGap="2px"
                     fxLayoutAlign="start center">
            <mat-icon class="error-icon">info</mat-icon>
            <span>A policy must have
            <span *ngIf="!selectedPmAccount; else selectedPmAccountName">
              {{ additionalInterest.name ? additionalInterest.name : communityName}}
            </span>
            listed as an additional interest before it can be submitted.</span>
          </mat-error>
        </div>

        <div *ngIf="false" style="margin: 20px 0 10px;">
          <mat-error fxLayout="row"
                     fxLayoutGap="2px"
                     fxLayoutAlign="start center">
            <mat-icon class="error-icon">info</mat-icon>
            <span>{{ 'tenant_boarding.please_finish_filling_out_this_form' | translate }}</span>
          </mat-error>
        </div>

        <div style="margin: 15px 0 25px;" class="tenant-boarding__text_thin tenant-boarding__text_italic">
          ** {{ 'tenant_boarding.i_attest_that_all_of_the_entered' | translate }}
        </div>
      </div>

      <div>
        <mat-error *ngIf="!isPolicyUserValid">A policy with this number has already been uploaded by another user.</mat-error>
      </div>

      <div style="margin-top: 10px;">
        <button mat-button
                type="submit"
                class="mat-btn warning full-width"
                [disabled]="loader || !form.get('additional_interest').value
                || getCoveragesInvalid
                || form.invalid || unitControl.invalid || !isPolicyUserValid"
                [class.spinner]="loader">
          {{ isValidInitForm ? ('form.submit' | translate) : 'form.continue' | translate }} **
        </button>
      </div>

      <div>
        <div *ngIf="inTenantBoarding" style="margin-top: 20px;" fxLayout="row" fxLayoutGap="10px" fxLayout.lt-sm="column">
          <button mat-button
                  type="button"
                  class="mat-btn primary full-width"
                  (click)="changeStep.emit('second')"
                  [disabled]="loader">
            {{ 'form.back' | translate }}
          </button>
          <button mat-button
                  type="button"
                  class="mat-btn tenant-btn full-width"
                  (click)="navigateToRentersInsurance()"
                  [disabled]="loader">
            {{ 'tenant_boarding.get_a_renters_insurance_quote' | translate }}
          </button>
          <button *ngIf="isMasterPolicy"
                  mat-button
                  type="button"
                  class="mat-btn tenant-btn full-width"
                  (click)="changeStep.emit('master-policy')"
                  [disabled]="loader">
            {{ 'tenant_boarding.select_community_master_coverage' | translate }}
          </button>
        </div>
      </div>
    </form>

  </div>
</div>
