import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import * as _moment from 'moment';
import { debounceTime, distinctUntilChanged, finalize, map } from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import { pipe, Subscription } from 'rxjs';
import {
  BrandingAdditionalInterest,
  GCPolicyUploadDefaultAddress,
  GCPolicyUploadNewAddress,
  PmaService
} from '../../pma.service';
import { AuthService } from '../../../core/services';
import { BrandingService } from '../../../core/services/branding.service';
import { BrandingInfo } from '../../../core/models/branding.model';
import { PmaDialogAdditionalInterestComponent } from './pma-dialog-additional-interest/pma-dialog-additional-interest.component';
import { PmAccount } from '../../../core/dto/pm-account';
import { ObjectIdValidator } from '../../../auth/_helpers/object-id.validator';
import { ResidentialService } from '../../../core/services/residential.service';
import { IframeModeService } from '../../../core/services/iframe-mode.service';
import { DialogPolicyNumberComponent } from './dialog-policy-number/dialog-policy-number.component';
import { ExternalPolicyFillService } from './external-policy-fill.service';
import { PolicyAssignService } from './policy-assign.service';
import { DialogMatchUserComponent } from './dialog-match-user/dialog-match-user.component';
import { UserMatchService } from '../../../auth/user-match.service';
import { pliuUserProfile, pliuUserAddress } from '../../../helpers/form-field-helper';

const moment = _moment;

const pliu = 'policy[policy_lease_insurable_users_attributes]';

@Component({
  selector: 'upload-coverage',
  templateUrl: './upload-coverage.component.html',
  styleUrls: ['./upload-coverage.component.scss']
})
export class UploadCoverageComponent implements OnInit, OnDestroy {
  @Output() changeStep = new EventEmitter();
  @Input() firstForm;
  form: FormGroup;
  @Input() communityName = 'Community Name';
  @Input() buildingName;
  @Input() unitName = 'Unit Number';
  @Input() fullAddress;
  @Input() isMasterPolicy;
  @Input() communityAdditionalInterestName;
  @Input() additionalInterestAddress;
  @Input() communityControl;
  @Input() county;
  @Input() buildingControl;
  @Input() unitControl;
  @Input() inTenantBoarding = true;
  @Input() isBrandingSecondNature;
  @Output() setAddress = new EventEmitter();
  @Input() pmAccountName;
  @Input() accountId;
  @Input() agencyId;
  @Input() addressAttributes = null;
  @Input() nonApplicableControl: FormControl;
  @Input() nonpreferredAccountSelection;
  @Input() nonpreferredAddressSelection;
  @Input() pmaSingleHouses;
  loader = false;

  userId;
  carrierControl = new FormControl();
  dogCoverageControl = new FormControl();
  liabilityCoverageControl = new FormControl();
  contentsCoverageControl = new FormControl();
  communityContentsCoverageMin;
  communityLiabilityCoverageMin;

  minDate = moment(new Date).subtract(2, 'year').toDate();
  maxDate;
  branding: BrandingInfo;
  brandingSlug: string;
  brandingAdditionalInterest: BrandingAdditionalInterest = null;

  subscription = new Subscription();

  GCPolicyUploadDefaultAddress = GCPolicyUploadDefaultAddress;
  GCPolicyUploadNewAddress = GCPolicyUploadNewAddress;

  fetchedCommunityAdditionalInterestName;
  fetchedAdditionalInterestAddress;

  additionalInterest: BrandingAdditionalInterest = null;

  slug;
  userAddressAttributes;
  liabilityCoverages = PmaService.getLiabilityCoverages();
  buildings;
  units;
  synonyms;
  policyPayloadCheck;
  isPolicyNumberValid = true;
  isPolicyUserValid = true;
  brandingId;

  constructor(private fb: FormBuilder,
              private router: Router,
              private dialog: MatDialog,
              private translate: TranslateService,
              private cd: ChangeDetectorRef,
              private toastr: ToastrService,
              private pmaService: PmaService,
              private authService: AuthService,
              private brandingService: BrandingService,
              private residentialService: ResidentialService,
              private IframeModeService: IframeModeService,
              private externalPolicyFillService: ExternalPolicyFillService,
              private policyAssignService: PolicyAssignService,
              private userMatchService: UserMatchService) {
    this.form = this.fb.group({
      first_name: ['', [Validators.required, Validators.maxLength(30)]],
      last_name: ['', [Validators.required, Validators.maxLength(30)]],
      middle_name: ['', [Validators.maxLength(25)]],
      email: ['', [Validators.required, Validators.email]],
      lease_start: ['', [Validators.required]],
      number: ['', [Validators.required]],
      policy_type_id: [1, []],
      effective_date: ['', [Validators.required]],
      expiration_date: [{ value: '' }, [Validators.required]],
      documents: [[], []],
      out_of_system_carrier_title: ['', [Validators.required]],
      additional_interest: [false, []],
      system_purchased: [false, []],
      users: this.fb.array([
        this.fb.group({
          email: ['', [Validators.email]],
          spouse: false,
          profile_attributes: this.fb.group({
            first_name: ['', [Validators.maxLength(30)]],
            last_name: ['', [Validators.maxLength(30)]],
            middle_name: ['', [Validators.maxLength(25)]],
          })
        })
      ])
    });
  }

  ngOnInit(): void {
    this.preSetForm();

    if (!this.inTenantBoarding) {
      this.preSetUserFields();
    }

    this.setBrandingAndAdditionalInterest();

    this.onCommunityChanges();

    this.onInsurableIdChanges();

    this.setAdditionalInterest();

    this.onLeaseStartChanges();

    this.onChangesPolicyNumber();

    this.userMatchService.onChangesUserInfo(this.form);

    this.onChangesEmail();

    //
    // if @Input() isBrandingSecondNature wasn't passed (happens on the standalone upload coverage proof form)
    // in this case we need to correctly define this property
    if (this.isBrandingSecondNature === undefined) {
      const slug = this.brandingService.getBrandingInfoSnapshot()?.profile_attributes.find(item => item.name === 'branding_profile_slug')?.value;
      this.isBrandingSecondNature = slug === 'second_nature' || slug === 'pinata';
    }
  }

  preSetForm() {
    this.form.get('first_name').patchValue(this.firstForm.get('first_name').value);
    this.form.get('last_name').patchValue(this.firstForm.get('last_name').value);
    this.form.get('middle_name').patchValue(this.firstForm.get('middle_name').value);
    this.form.get('email').patchValue(this.firstForm.get('email').value);
  }

  preSetUserFields() {
    const user = this.authService.getCurrentUser();
    this.userId = user && user.id ? user.id : null;
    if (user && user.profile_attributes) {
      this.form.get('first_name').patchValue(user.profile_attributes.first_name);
      this.form.get('last_name').patchValue(user.profile_attributes.last_name);
    }
    if (user && user.email) {
      this.form.get('email').patchValue(user.email);
    }
  };

  setBrandingAndAdditionalInterest() {
    const sub = this.brandingService.brandingInfo$.subscribe((b: BrandingInfo) => {
      this.branding = b;
      this.brandingSlug = this.brandingService.getBrandingInfoSnapshot()?.profile_attributes.find(item => item.name === 'branding_profile_slug')?.value;
      this.brandingId = b.id;
      this.pmaService.getAdditionalInterest(b.profile_attributes);
    });
    this.subscription.add(sub);
  }

  setAdditionalInterest() {
    const branding = this.brandingService.getBrandingInfoSnapshot();
    this.slug = branding?.profile_attributes.find(item => item.name === 'branding_profile_slug')?.value;

    this.additionalInterest = this.pmaService.getAdditionalInterest(branding.profile_attributes);

    if (branding.profileable_type === 'Account' && ['garden_communities', 'essex', 'second_nature'].includes(this.slug)) {
      this.pmAccountName = this.slug === 'second_nature' ? 'Sweyer Property Management' : branding.profileable_title;
      this.communityName = this.pmAccountName;
    } else if (!this.pmAccountName) {
      this.pmAccountName = branding.profileable_title;
    }
  }

  onCommunityChanges() {
     this.communityControl.valueChanges.subscribe(community => {
      if (community && community.id) {
        this.pmaService.getAdditionalInterestByInsurableId(community.id).subscribe((res: any) => {
          this.fetchedCommunityAdditionalInterestName = this.pmaService.setAdditionalInterestName(res, community.title);
          this.fetchedAdditionalInterestAddress = this.pmaService.setAdditionalInterestAddress(res, community.title);
        });
        this.setCoverageValidators();
      }
    });
  }

  onInsurableIdChanges() {
    if (this.firstForm && this.firstForm.get('insurable_id')) {
      this.subscription.add(
        this.firstForm.get('insurable_id').valueChanges.subscribe(id => {
          if (id) {
            this.setCoverageValidators();
          }
        })
      );
    }
  }

  onLeaseStartChanges() {
    this.subscription.add(
      this.form.get('lease_start').valueChanges.subscribe(date => {
        if (date) {
          this.setCoverageValidators();
        }
      })
    );
  }

  setCoverageValidators() {
    const communityId = this.getInsurableId() ? this.getInsurableId() : this.getCommunityControlId();
    const leaseStartDate = this.getLeaseStartDate();
    if (communityId && leaseStartDate) {
      this.fetchCoverageValidations(communityId, leaseStartDate);
    }
  }

  getInsurableId() {
    return this.firstForm && this.firstForm.get('insurable_id') && this.firstForm.get('insurable_id').value ? this.firstForm.get('insurable_id').value : null;
  }

  getCommunityControlId() {
    return this.communityControl && this.communityControl.value && this.communityControl.value.id ? this.communityControl.value.id : null;
  }

  getLeaseStartDate() {
    return this.form.get('lease_start') && this.form.get('lease_start').value ? moment(this.form.get('lease_start').value).format('YYYY-MM-DD') : null;
  }

  fetchCoverageValidations(insurableId, leastStartDate) {
    this.residentialService.getCoverageRequirements(insurableId, leastStartDate).subscribe((res: any) => {
      const data = res && res.data ? res.data : [];

      const liability = data.find(item => item.designation === 'liability');
      const contents = data.find(item => item.designation === 'contents');

      this.communityContentsCoverageMin = contents && contents.amount ? contents.amount : null;
      this.communityLiabilityCoverageMin = liability && liability.amount ? liability.amount : null;
      if (this.communityContentsCoverageMin) {
        const communityContentsCoverageMin = this.communityContentsCoverageMin / 100;
        this.contentsCoverageControl.setValidators([Validators.required, Validators.min(communityContentsCoverageMin)]);
        this.contentsCoverageControl.updateValueAndValidity();
      }
      if (this.communityLiabilityCoverageMin) {
        const communityLiabilityCoverageMin = this.communityLiabilityCoverageMin / 100;
        this.liabilityCoverageControl.setValidators([Validators.required, Validators.min(communityLiabilityCoverageMin * 100)]);
        this.liabilityCoverageControl.updateValueAndValidity();
      }
    });
  }

  setUserAddressAttributes(address) {
    const full = address && address.full ? address.full : null;
    if (full) {
      this.setFullAddress(full);
    }
    this.userAddressAttributes = {
      street_name: address && address.street_name ? address.street_name : '',
      street_number: address && address.street_number ? address.street_number : '',
      street_two: address && address.street_two ? address.street_two : '',
      city: address && address.city ? address.city : '',
      state: address && address.state ? address.state : '',
      zip_code: address && address.zip_code ? address.zip_code : ''
    }
  }

  openAdditonalInterestDialog() {
    this.dialog.open(PmaDialogAdditionalInterestComponent, { autoFocus: false, maxWidth: 450 });
  }

  updateDate($event) {
    const date = new Date(this.form.value.effective_date);
    date.setFullYear(date.getFullYear() + 1);
    this.form.controls.expiration_date.setValue(date);
    this.maxDate = moment(this.form.value.effective_date).add(1, 'year').toDate();
  }

  requireDocuments() {
    if (this.form.get('documents').value && this.form.get('documents').value.length > 0) {
      return false;
    }
    this.toastr.error('Declaration Document is required');
    return true;
  }

  submitForm() {
    if (this.requireDocuments()) {
      return;
    }
    this.uploadExternalPolicy();
  }

  openDialogPolicyNumber(withExternalPolicy = false) {
    const dialog = this.dialog.open(DialogPolicyNumberComponent, { autoFocus: false, maxWidth: 450 });
    dialog.afterClosed().subscribe((res) => {
      this.isPolicyNumberValid = !!res;
      if (this.isPolicyNumberValid) {
        this.externalPolicyFillService.prefillForm(
          this.form,
          this.liabilityCoverageControl, this.contentsCoverageControl,
          this.policyPayloadCheck
        );
      }
      if (this.isPolicyNumberValid && withExternalPolicy) {
        this.uploadExternalPolicy();
      }
    });
  }

  uploadExternalPolicy() {
    this.loader = true;
    this.cd.markForCheck();
    if (this.form.valid) {
      const formData = this.getFormData();
      this.pmaService.addCoverageProof(formData).pipe(
        finalize(() => this.loader = false)
      ).subscribe((res: any) => {
        const msg = res && res.message ? res.message : this.translate.instant('user.success');
        this.toastr.success(msg);
        this.changeStep.emit('documents-reviewed');
        this.IframeModeService.sendMessage({
          event_type: 'upload'
        });
      }, res => {
        this.handleError(res);
      });
    } else {
      this.loader = false;
    }
  }

  handleError(res) {
    const msg = res && res.documents ? res.documents[0] :
      // res && res.originError && res.originError.error && res.originError.error['policy_insurables.insurable'] ? this.translate.instant('user.insurable_must_exist') :
      res && res.originError && res.originError.error && res.originError.error['policy_lease_insurable_users.insurable'] ? this.translate.instant('user.insurable_must_exist') :
        res && res.error === 'carrier_invalid' ? this.translate.instant('tenant_boarding.select_carrier') :
          res && res.error === 'policy_in_system_violation' ? this.translate.instant('tenant_boarding.policy_in_system_violation') :
          res && res.error && res.message ? res.message :
            this.translate.instant('user.something_went_wrong');
    this.toastr.error(msg);
  }

  getFormData() {
    const formData = new FormData();
    this.setInsurableIdFormData(formData);
    this.setAccountIdFormData(formData);
    formData.append(`policy[agency_id]`, this.branding?.agency_id?.toString() || '');
    formData.append('policy[address]', this.fullAddress);
    formData.append(`policy[number]`, this.form.get('number').value);
    formData.append(`policy[policy_type_id]`, this.form.get('policy_type_id').value);
    formData.append(`policy[status]`, 'EXTERNAL_UNVERIFIED');
    formData.append(`policy[lease_start]`, moment(this.form.get('lease_start').value).format('YYYY-MM-DD'));
    formData.append(`policy[effective_date]`, moment(this.form.get('effective_date').value).format('YYYY-MM-DD'));
    formData.append(`policy[expiration_date]`, moment(this.form.get('expiration_date').value).format('YYYY-MM-DD'));
    formData.append(`policy[additional_interest]`, this.form.get('additional_interest').value);
    formData.append(`policy[system_purchased]`, this.form.get('system_purchased').value);
    // formData.append(`policy[policy_users_attributes][0][spouse]`, 'false');
    // formData.append(`policy[policy_users_attributes][0][primary]`, 'true');
    // formData.append('policy[policy_users_attributes][0][user_attributes][email]', this.form.get('email').value);
    // formData.append('policy[policy_users_attributes][0][user_attributes][profile_attributes][first_name]', this.form.get('first_name').value);
    // formData.append('policy[policy_users_attributes][0][user_attributes][profile_attributes][last_name]', this.form.get('last_name').value);
    // formData.append('policy[policy_users_attributes][0][user_attributes][profile_attributes][middle_name]', this.form.get('middle_name').value);
    formData.append(`${pliu}[0][spouse]`, 'false');
    formData.append(`${pliu}[0][policy_primary]`, 'true');
    formData.append(`${pliu}[0][user_attributes][email]`, this.form.get('email').value);
    formData.append(`${pliuUserProfile(0, 'first_name')}`, this.form.get('first_name').value);
    formData.append(`${pliuUserProfile(0, 'last_name')}`, this.form.get('last_name').value);
    formData.append(`${pliuUserProfile(0, 'middle_name')}`, this.form.get('middle_name').value);
    this.setCarrier(formData);
    this.setUserAddressFormData(formData, 0);
    this.setUsersFormData(formData);
    this.setDocuments(formData, this.form.get('documents').value);
    this.setLiabilityCoverageFormData(formData);
    if (this.isBrandingSecondNature && this.dogCoverageControl.value) {
      this.setPetDamageFormData(formData);
    }
    return formData;
  }

  setCarrier(formData) {
    const isCarrierId = this.form.get('out_of_system_carrier_title').value && this.form.get('out_of_system_carrier_title').value.id;
    const firstSynonymId = this.synonyms && this.synonyms[0] && this.synonyms[0].id ? this.synonyms[0].id : null;
    const isOneOption = this.form.get('out_of_system_carrier_title').value && firstSynonymId && this.synonyms.length === 1;
    if (isCarrierId) {
      formData.append(`policy[carrier_id]`, this.form.get('out_of_system_carrier_title').value.id);
    } else if (isOneOption) {
      formData.append(`policy[carrier_id]`, firstSynonymId);
    } else {
      formData.append(`policy[out_of_system_carrier_title]`, this.form.get('out_of_system_carrier_title').value);
    }
  }

  setInsurableIdFormData(formData) {
    let insurableId;
    if (this.firstForm && this.firstForm.get('address') && this.firstForm.get('insurable_id')) {
      insurableId = this.firstForm.get('insurable_id').value;
    } else {
      insurableId = this.unitControl && this.unitControl.value && this.unitControl.value.id ? this.unitControl.value.id :
        this.buildingControl && this.buildingControl.value && this.buildingControl.value.id ? this.buildingControl.value.id :
          this.communityControl.value.id;
    }
    // formData.append('policy[policy_insurables_attributes][0][insurable_id]', insurableId);
    formData.append(`${pliu}[0][insurable_id]`, insurableId);
  }

  setAccountIdFormData(formData) {
    let accountId = this.branding?.account_id?.toString() || '';
    if (this.firstForm.get('pm_account')) {
      accountId = (this.firstForm.get('pm_account').value as PmAccount).id;
    }
    formData.append(`policy[account_id]`, accountId);
  }

  setDocuments(formData, documents) {
    if (documents) {
      documents.forEach(doc => {
        formData.append('policy[documents][]', doc);
      });
    }
  }

  setLiabilityCoverageFormData(formData) {
    formData.append(`policy[policy_coverages_attributes][0][deductible]`, '0');
    formData.append(`policy[policy_coverages_attributes][0][designation]`, 'liability');
    formData.append(`policy[policy_coverages_attributes][0][limit]`, this.liabilityCoverageControl.value);
    formData.append(`policy[policy_coverages_attributes][0][title]`, 'Liability Coverage');
    formData.append(`policy[policy_coverages_attributes][0][enabled]`, 'true');
    formData.append(`policy[policy_coverages_attributes][1][deductible]`, '0');
    formData.append(`policy[policy_coverages_attributes][1][designation]`, 'contents');
    formData.append(`policy[policy_coverages_attributes][1][limit]`, this.contentsCoverageControl.value * 100);
    formData.append(`policy[policy_coverages_attributes][1][title]`, 'Contents Coverage');
    formData.append(`policy[policy_coverages_attributes][1][enabled]`, 'true');
  }

  setPetDamageFormData(formData) {
    formData.append(`policy[policy_coverages_attributes][2][deductible]`, '0');
    formData.append(`policy[policy_coverages_attributes][2][designation]`, 'pet_damage');
    formData.append(`policy[policy_coverages_attributes][2][limit]`, '0');
    formData.append(`policy[policy_coverages_attributes][2][title]`, 'Pet Damage');
    formData.append(`policy[policy_coverages_attributes][2][enabled]`, 'true');
  }

  setUsersFormData(formData) {
    if (this.form && this.form.get('users') && this.form.get('users').value) {
      this.form.get('users').value.forEach((user, i) => {
        if (this.isNotEmptyUser(user)) {
          // formData.append(`policy[policy_users_attributes][${i + 1}][user_attributes][email]`, user.email);
          // formData.append(`policy[policy_users_attributes][${i + 1}][spouse]`, user.spouse);
          // formData.append(`policy[policy_users_attributes][${i + 1}][primary]`, 'false');
          formData.append(`${pliu}[${i + 1}][user_attributes][email]`, user.email);
          formData.append(`${pliu}[${i + 1}][spouse]`, user.spouse);
          formData.append(`${pliu}[${i + 1}][policy_primary]`, 'false');
          Object.keys(user.profile_attributes).forEach(key => {
            if (key === 'birth_date') {
              // formData.append(`policy[policy_users_attributes][${i + 1}][user_attributes][profile_attributes][${key}]`, moment(user.profile_attributes[key]).format('YYYY-MM-DD'));
              formData.append(`${pliuUserProfile(i+1, key)}`, moment(user.profile_attributes[key]).format('YYYY-MM-DD'));
            } else {
              // formData.append(`policy[policy_users_attributes][${i + 1}][user_attributes][profile_attributes][${key}]`, user.profile_attributes[key]);
              formData.append(`${pliuUserProfile(i+1, key)}`, user.profile_attributes[key]);
            }
          });
          this.setUserAddressFormData(formData,i + 1);
        }
      });
    }
  }

  isNotEmptyUser(user) {
    if (user && user.email) {
      return true;
    }
    const profileAttributes = user.profile_attributes;
    const found = Object.keys(profileAttributes).find(key => profileAttributes[key]);
    return !!found;
  }

  setUserAddressFormData(formData, index) {
    const addressAttributes = this.addressAttributes ? this.addressAttributes : this.userAddressAttributes ? this.userAddressAttributes : null;
    if (addressAttributes) {
      // formData.append(`policy[policy_users_attributes][${index}][user_attributes][address_attributes][street_number]`, addressAttributes.street_number);
      // formData.append(`policy[policy_users_attributes][${index}][user_attributes][address_attributes][street_name]`, addressAttributes.street_name);
      // formData.append(`policy[policy_users_attributes][${index}][user_attributes][address_attributes][street_two]`, addressAttributes.street_two);
      // formData.append(`policy[policy_users_attributes][${index}][user_attributes][address_attributes][city]`, addressAttributes.city);
      // formData.append(`policy[policy_users_attributes][${index}][user_attributes][address_attributes][state]`, addressAttributes.state);
      // formData.append(`policy[policy_users_attributes][${index}][user_attributes][address_attributes][zip_code]`, addressAttributes.zip_code);
      formData.append(`${pliuUserAddress(index, 'street_number')}`, addressAttributes.street_number);
      formData.append(`${pliuUserAddress(index, 'street_name')}`, addressAttributes.street_name);
      formData.append(`${pliuUserAddress(index, 'street_two')}`, addressAttributes.street_two);
      formData.append(`${pliuUserAddress(index, 'city')}`, addressAttributes.city);
      formData.append(`${pliuUserAddress(index, 'state')}`, addressAttributes.state);
      formData.append(`${pliuUserAddress(index, 'zip_code')}`, addressAttributes.zip_code);
    }
  }

  onChangesPolicyNumber() {
    this.subscription.add(
      this.form.get('number').valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(800),
      ).subscribe(number => {
        if (number) {
          this.policyAssignCheck();
        }
      })
    );
  }

  policyCheck() {
    this.form.controls['number'].setErrors(null);
    const number = this.form.get('number') && this.form.get('number').value;
    const email = this.form.get('email').value;
    if (number) {
      this.externalPolicyFillService.externalPolicyCheck(number, email).subscribe((res: any) => {
        if (res && res.policy) {
          const isPolicyInSystemError = res.policy && res.policy.policy_in_system ? res.policy.policy_in_system : false;
          if (isPolicyInSystemError) {
            this.form.controls['number'].setErrors({'in_system': true});
          } else {
            this.openDialogPolicyNumber();
          }
          this.policyPayloadCheck = res;
        }
      })
    }
  }

  onChangesEmail() {
    this.subscription.add(
      this.form.get('email').valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(800),
      ).subscribe(email => {
        if (email && this.form.get('email').valid) {
          this.policyAssignCheck();
        }
      })
    );
  }

  policyAssignCheck() {
    this.isPolicyUserValid = true
    const body = this.policyAssignService.policyAssignGetBody(this.form, this.userId);
    if (body) {
      this.policyAssignService.assignCheck(body).subscribe((res: any) => {
        if (res && res.belongs_to_provided_user === false && res.exists === true) {
          this.isPolicyUserValid = false;
          this.toastr.error('A policy with this number has already been uploaded by another user.');
        } else {
          this.policyCheck();
        }
      }, err => {
        this.policyCheck();
      });
    }
  }

  navigateToUserProfile() {
    this.router.navigateByUrl('user/policies');
  }

  navigateToRentersInsurance() {
    this.pmaService.navigateToResidential(this.form, this.fullAddress, this.county, this.currentAddressAttributes, this.unitControl);
  }

  setFullAddress(fullAddress) {
    this.setAddress.emit(fullAddress);
  }

  setSynonyms(synonyms) {
    this.synonyms = synonyms;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get currentAddressAttributes() {
    return this.addressAttributes ? this.addressAttributes : this.userAddressAttributes ? this.userAddressAttributes : null;
  }


  get f() {
    return this.form.controls;
  }

  get selectedPmAccount() {
    return this.firstForm && this.firstForm.get('pm_account') ? this.firstForm.get('pm_account').value : null;
  }

  get getCommunityAdditionalInterestName() {
    return this.inTenantBoarding ? this.communityAdditionalInterestName : this.fetchedCommunityAdditionalInterestName;
  }

  get getAdditionalInterestAddress() {
    return this.inTenantBoarding ? this.additionalInterestAddress : this.fetchedAdditionalInterestAddress;
  }

  get isValidInitForm() {
    return this.form.get('first_name').value && this.form.get('last_name').value &&
      this.form.get('email').value && this.form.get('email').valid &&
      (this.firstForm.get('unit').valid || this.firstForm.get('unit').disabled) &&
      this.userAddressAttributes && this.userAddressAttributes.street_name &&
      this.isPMAccount;
  }

  get isPMAccount() {
    return !this.nonpreferredAccountSelection ||
      (this.nonpreferredAccountSelection && this.firstForm.get('pm_account') && this.firstForm.get('pm_account').value);
  }

  get getCoveragesInvalid() {
    return this.liabilityCoverageControl.invalid || this.contentsCoverageControl.invalid
  }

  get isGetQuote() {
    const isGetQuote = this.brandingService.getBrandingInfoSnapshot()?.profile_attributes.find(item => item.name === 'is_get_quote')?.value;
    return isGetQuote;
  }

  get showAdditionalEmailNotice(): boolean {
    return this.branding?.agency_id === 416 || this.branding?.profileable_title.toLowerCase() === 'second nature';
  }
}
