import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { PmaService } from '../pma.service';

@Component({
  selector: 'pma-carrier',
  templateUrl: './pma-carrier.component.html',
  styleUrls: ['./pma-carrier.component.scss']
})
export class PmaCarrierComponent implements OnInit {
  @Input() carrierControl: FormControl;
  @Input() synonyms;
  @Output() setSynonyms = new EventEmitter();
  carriers;

  constructor(private pmaService: PmaService) {
  }

  ngOnInit(): void {
    this.pmaService.getCarriers().subscribe((res: any) => {
      this.carriers = res && res.data ? res.data : [];
      this.setFilteredCarriers();
    });
  }

  setFilteredCarriers() {
    this.carrierControl.valueChanges
      .pipe(
        startWith(''),
        map(value => value || ''),
        map(value => typeof value === 'string' ? value : value.title),
      ).subscribe(title => {
        const synonyms = title ? this.filterCarriers(this.carriers, title) : this.carriers.slice(0)
        this.setSynonyms.emit(synonyms);
    });
  }

  private filterCarriers(carriers, searchTerm: string) {
    const filterValue = searchTerm.toLowerCase();
    return carriers.filter(item => {
      const foundSynonym = item.synonyms && item.synonyms.toLowerCase().includes(filterValue);
      const foundTitle = item.title && item.title.toLowerCase().includes(filterValue);
      return foundSynonym || foundTitle;
    });
  }

  displayFnCarrier(carrier) {
    return carrier ? carrier.title : undefined;
  }

  reset() {
    this.carrierControl.reset(null);
  }
}
