import { Component, Input, OnDestroy, OnInit, Renderer2, } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription } from 'rxjs';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services';
import { BrandingService } from 'src/app/core/services/branding.service';

export const CLAIMS_URL = 'https://filemyclaim.io';

@Component({
  selector: 'header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit, OnDestroy {
  showMenu = false;
  menuItems = null;
  additionalMenuItems = null;
  curentUrl = null;
  isUpload;
  private menuSub: Subscription;

  constructor(
    private renderer: Renderer2,
    private brandingService: BrandingService,
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (event) { this.curentUrl = event.urlAfterRedirects; }
    });
  }

  ngOnInit(): void {
    this.translateService.get('menu.login').pipe(take(1)).subscribe(() => {
      this.setMenu();
    })
  }

  ngOnDestroy(): void {
    this.menuSub.unsubscribe();
  }

  logout(event) {
    event.preventDefault();
    this.authService.signOut().subscribe();
    this.closeMenu();
  }

  closeMenu() {
    if (this.showMenu) {
      this.showMenu = false;
    }
    this.renderer.removeClass(document.body, 'no-scroll');
  }

  openMenu() {
    this.showMenu = !this.showMenu;
    this.renderer.addClass(document.body, 'no-scroll');
  }

  handleCustomMenuClick(event, url): void {
    event.preventDefault();
    if (url.startsWith('http') || url.startsWith('https')) {
      window.open(url, '_blank').focus();
    } else {
      this.router.navigateByUrl(url);
    }
    this.closeMenu();
  }

  private setMenu() {
    this.menuSub = of([]).pipe(
      withLatestFrom(
        this.brandingService.dropdownMenuHeader$,
        this.brandingService.menuHeader$
      )
    ).subscribe(([arr, menuItems, additionalMenuItems]) => {
      let mainMenu = [];
      if (menuItems && menuItems.enabled) {
        mainMenu = [ ...menuItems.values ];
      } else {
        mainMenu = [
          { url: '/faq', title: this.translateService.instant('menu.faq') },
          { url: '/About-Us', title: this.translateService.instant('menu.about_us') },
          { url: '/Contact-Us', title: this.translateService.instant('menu.contact_us') }
        ];
      }
      mainMenu = mainMenu.filter(item => !item.url.includes('user/profile'));

      let additionalMenu = [];
      if (additionalMenuItems && additionalMenuItems.enabled) {
        additionalMenu = [ ...additionalMenuItems.values ];
      } else {
        additionalMenu = [
          { url: '/faq', title: this.translateService.instant('menu.faq') },
          { url: 'https://filemyclaim.io', title: this.translateService.instant('menu.claims') }
        ];
      }

      this.setUploadBranding();

      if (this.isUpload) {
        const uploadCoverageProof = {
          url: '/upload-coverage-proof',
          title: this.translateService.instant('menu.upload_insurance'),
        };
        mainMenu.push(uploadCoverageProof);
        additionalMenu.push(uploadCoverageProof);
      }
      this.menuItems = this.addClaimsLink(mainMenu);
      this.additionalMenuItems = this.addClaimsLink(additionalMenu);
    })
  }

  setUploadBranding() {
    const profileAttributes = this.brandingService?.getBrandingInfoSnapshot()?.profile_attributes;
    if (profileAttributes) {
      const isUpload = profileAttributes.find(item => item.name === 'upload_external_policy');
      this.isUpload = isUpload && isUpload.value && isUpload.value === 'true';
    }
  }

  private addClaimsLink(menu) {
    return menu.map(val => {
      return val.url.includes('/claims') ? { ...val, url: CLAIMS_URL} : val;
    });
  }
}
