import { stateAbbreviations } from '../constant';
import {
  AdditionalInformationFormData,
  GuaranteeFormData,
  GuaranteeModel
} from '../models/guarantee.models';
import * as _ from 'lodash';
interface GuaranteeOption {
  months: number;
  multiplier: number;
  class?: string;
}

export class GuaranteeFormUtils {
  public static GENDER_LIST = [{
    label: 'form.male',
    value: 'male'
  }, {
    label: 'form.female',
    value: 'female'
  }, {
    label: 'form.other',
    value: 'other'
  }];
  public static EMPLOYMENT_TYPE_LIST = ['Full Time', 'Part Time', 'Self Employed', 'Unemployed'];
  public static GUARANTEE_OPTIONS_LIST = [3, 6, 12];
  public static GUARANTEE_OPTIONS: GuaranteeOption[] = [
    {
      months: 3,
      multiplier: 0.035
    },
    {
      months: 6,
      multiplier: 0.075
    },
    {
      months: 12,
      multiplier: 0.09,
      class: 'mat-radio_12-months'
    }
  ];
  public static SALUTATION_LIST = [{
    label: 'form.mr',
    value: 'mr'
  }, {
    label: 'form.mrs',
    value: 'mrs'
  }, {
    label: 'form.miss',
    value: 'miss'
  }, {
    label: 'form.dr',
    value: 'dr'
  }];
  public static STATE_LIST = stateAbbreviations;
  public static COUNTRY_LIST = [{ label: 'form.united_states', value: 'United States' }];

  public static toGuaranteeFormData(data: GuaranteeModel): GuaranteeFormData {
    // const userAttributes = data.policy_users_attributes[0];
    const userAttributes = data.policy_lease_insurable_users_attributes[0];
    return {
      name: userAttributes.user_attributes.profile_attributes.first_name || '',
      lastname: userAttributes.user_attributes.profile_attributes.last_name || '',
      salutation: '',
      gender: '',
      phoneNumber: userAttributes.user_attributes.profile_attributes.contact_phone || '',
      date: userAttributes.user_attributes.profile_attributes.birth_date || '',
      email: userAttributes.user_attributes.email || '',
      address: data.fields.employment.primary_applicant.address.street_name || '',
      address2: data.fields.employment.primary_applicant.address.street_two || '',
      city:  data.fields.employment.primary_applicant.address.city || '',
      state:  data.fields.employment.primary_applicant.address.state || '',
      zip:  data.fields.employment.primary_applicant.address.zip_code || '',
      country:  data.fields.employment.primary_applicant.address.country || '',
      employmentType: data.fields.employment.primary_applicant.employment_type || '',
      employmentDescription: data.fields.employment.primary_applicant.job_description || '',
      monthlyRent: data.fields.monthly_rent || 0,
      guaranteeOption: data.fields.guarantee_option || 0,
      expirationDate: data.expiration_date,
      effectiveDate: data.effective_date,
      addTenant: false,
      jointTenant: {
        name: userAttributes.user_attributes.profile_attributes.first_name,
        lastname: userAttributes.user_attributes.profile_attributes.last_name,
        salutation: userAttributes.user_attributes.profile_attributes.salutation,
        gender: userAttributes.user_attributes.profile_attributes.gender,
        email: userAttributes.user_attributes.email,
        phoneNumber: userAttributes.user_attributes.profile_attributes.contact_phone,
        birthdate: userAttributes.user_attributes.profile_attributes.birth_date,
        employmentType: data.fields.employment.secondary_applicant.employment_type,
        employmentDescription: data.fields.employment.secondary_applicant.job_description,
        monthlyIncome: data.fields.employment.secondary_applicant.monthly_income
      }
    };
  }

  public static toAdditionalInformationFormData(data: GuaranteeModel): AdditionalInformationFormData {
    return {
      companyName: data.fields.landlord.company,
      name: data.fields.landlord.first_name,
      lastname: data.fields.landlord.last_name,
      phoneNumber: data.fields.landlord.phone_number,
      email: data.fields.landlord.email,
      employer: {
        name: data.fields.employment.primary_applicant.company_name,
        address: data.fields.employment.primary_applicant.address.street_name,
        address2: data.fields.employment.primary_applicant.address.street_two,
        city: data.fields.employment.primary_applicant.address.city,
        state: data.fields.employment.primary_applicant.address.state,
        zip: data.fields.employment.primary_applicant.address.zip_code,
        country: data.fields.employment.primary_applicant.address.country,
        phoneNumber: data.fields.employment.primary_applicant.company_phone_number
      },
      joint_employer: {
        name: data.fields.employment.secondary_applicant.company_name,
        address: data.fields.employment.secondary_applicant.address.street_name,
        address2: data.fields.employment.secondary_applicant.address.street_two,
        city: data.fields.employment.secondary_applicant.address.city,
        state: data.fields.employment.secondary_applicant.address.state,
        zip: data.fields.employment.secondary_applicant.address.zip_code,
        country: data.fields.employment.secondary_applicant.address.country,
        phoneNumber: data.fields.employment.secondary_applicant.company_phone_number
      }
    };
  }

  public static guaranteeFormToServerData(data: GuaranteeFormData): GuaranteeModel {
    const guaranteeServerData =  {
      effective_date: data.effectiveDate,
      expiration_date: data.expirationDate,
      fields: {
        employment: {
          primary_applicant: {
            employment_type: data.employmentType,
            job_description: data.employmentDescription,
          }
        },
        monthly_rent: data.monthlyRent,
        guarantee_option: data.guaranteeOption
      },
      // policy_users_attributes: [
      //   {
      //     user_attributes: {
      //       email: data.email,
      //       profile_attributes: {
      //         first_name: data.name,
      //         last_name: data.lastname,
      //         contact_phone: data.phoneNumber,
      //         birth_date: data.date,
      //         salutation: data.salutation,
      //         gender: data.gender
      //       },
      //       address_attributes: {
      //         street_name: data.address,
      //         street_two: data.address2,
      //         city: data.city,
      //         state: data.state,
      //         zip_code: data.zip,
      //         country: data.country
      //       }
      //     }
      //   }
      // ]
      policy_lease_insurable_users_attributes: [
        {
          user_attributes: {
            email: data.email,
            profile_attributes: {
              first_name: data.name,
              last_name: data.lastname,
              contact_phone: data.phoneNumber,
              birth_date: data.date,
              salutation: data.salutation,
              gender: data.gender
            },
            address_attributes: {
              street_name: data.address,
              street_two: data.address2,
              city: data.city,
              state: data.state,
              zip_code: data.zip,
              country: data.country
            }
          }
        }
      ]
    } as any;
    let jointServerData = {};
    if (data.addTenant) {
      const jointData = data.jointTenant;
      jointServerData = {
        fields: {
          employment: {
            secondary_applicant: {
              employment_type: jointData.employmentType,
              job_description: jointData.employmentDescription,
              monthly_income: jointData.monthlyIncome
            }
          }
        },
        // policy_users_attributes: [
        //   {} as any,
        //   {
        //     spouse: false,
        //     user_attributes: {
        //       email: jointData.email,
        //       profile_attributes: {
        //         first_name: jointData.name,
        //         last_name: jointData.lastname,
        //         salutation: jointData.salutation,
        //         gender: jointData.gender,
        //         contact_phone: jointData.phoneNumber,
        //         birth_date: jointData.birthdate,
        //       }
        //     }
        //   }
        // ]
        policy_lease_insurable_users_attributes: [
          {} as any,
          {
            spouse: false,
            user_attributes: {
              email: jointData.email,
              profile_attributes: {
                first_name: jointData.name,
                last_name: jointData.lastname,
                salutation: jointData.salutation,
                gender: jointData.gender,
                contact_phone: jointData.phoneNumber,
                birth_date: jointData.birthdate,
              }
            }
          }
        ]
      };
    }
    return _.merge(guaranteeServerData, jointServerData) as GuaranteeModel;
  }

  public static additionalInformationFormToServerData(data: AdditionalInformationFormData): GuaranteeModel {
    const employer = data.employer || {} as any;
    const jointEmployer = data.joint_employer || {} as any;
    return {
      fields: {
        landlord : {
          company: data.companyName,
          first_name: data.name,
          last_name: data.lastname,
          phone_number: data.phoneNumber,
          email: data.email
        },
        employment: {
          primary_applicant: {
            company_name: data.employer.name,
            company_phone_number: data.employer.phoneNumber,
            address: {
              street_name: employer.address,
              street_two: employer.address2,
              city: employer.city,
              state: employer.state,
              zip_code: employer.zip,
              country: employer.country,
            }
          },
          secondary_applicant: {
            company_name: jointEmployer.name,
            company_phone_number: jointEmployer.phoneNumber,
            address: {
              street_name: jointEmployer.address,
              street_two: jointEmployer.address2,
              city: jointEmployer.city,
              state: jointEmployer.state,
              zip_code: jointEmployer.zip,
              country: jointEmployer.country,
            }
          }
        }
      }
    } as any;
  }

  // SAFARI FIX.Null values break select tags. More info GCV2-1916.
  public static setFormDataDefaultValues(formData): any {
    return Object.entries(formData).reduce((acc, [k, value]) => {
      if (typeof value === 'object' && value !== null) {
        return Object.assign(acc, {[k] : this.setFormDataDefaultValues(value)});
      } else {
        return Object.assign(acc, {[k]: value || ''});
      }
    }, {});
  }
}
